import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Button,
  Grid,
  TextField,
  InputAdornment,
  CircularProgress,
  Card,
  CardContent,
  useMediaQuery,
  Modal,
  FormLabel,
} from "@mui/material";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import usFlag from "../assets/us.png";
import indiaFlag from "../assets/in.png";
import Paper from "@mui/material/Paper";
import {
  VerifiedUser,
  Headphones,
  CurrencyExchange,
} from "@mui/icons-material";
import Image from "../assets/faq.png";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../reducers/exchangeRateReducer";
import { Link, useNavigate } from "react-router-dom";
import CloseIcon from "@mui/icons-material/Close";
import TestimonialsCarousel from "./TestimonialsCarousel";
import AppleIcon from "@mui/icons-material/Apple";
import ShopIcon from "@mui/icons-material/Shop";
import { analytics } from "../helper/helper";
import APP_STORE_ICON from "../assets/appStore.svg";
import PLAY_STORE_ICON from "../assets/playStore.svg";
import Stepper from "./HomeContent/stepper";

const HomeContent = () => {
  const isMobile = useMediaQuery("(max-width:400px)");
  const navigate = useNavigate();
  const [isIOS, setIsIOS] = useState(false);
  const [isAndroid, setIsAndroid] = useState(false);
  const handleSignUpClick = () => {
    navigate("/login");
  };
  const dispatch = useDispatch();
  const store = useSelector((state) => state.exchangeRate);
  const [sourceAmount, setSourceAmount] = useState("1000.00");
  const [destinationAmount, setDestinationAmount] = useState("");
  const { bannerData } = useSelector((state) => state.resource);
  useEffect(() => {
    dispatch(fetchExchangeRate());
    if (store.exchangeRate) {
      const initialDestinationAmount = (
        parseFloat("1000.00") * store.exchangeRate
      ).toFixed(2);
      setDestinationAmount(initialDestinationAmount);
    }

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if it's an iOS device
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      setIsIOS(true);
    } 
    // else if (/Android/.test(userAgent)) {
    //   setIsAndroid(true);
    // }
  }, [dispatch, store.exchangeRate]);

  const [open, setOpen] = React.useState(true);
  const handleClose = () => setOpen(false);
  return (
    <Box bgcolor={"brandLight.main"}>
      {bannerData.isShow && (
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          disableAutoFocus={true}
        >
          <Box
            sx={{
              position: "absolute",
              top: "5%",
              left: "50%",
              transform: "translate(-50%, 0%)",
              width: 600,
              maxWidth: "100%",
              bgcolor: `#${bannerData.backgroundColor}`,
              border: "2px solid #000",
              boxShadow: 24,
            }}
          >
            <Box sx={{ position: "relative", padding: 4 }}>
              <Button
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  padding: 0,
                  top: "5%",
                  right: "0%",
                  bgcolor: `#${bannerData.backgroundColor}`,
                }}
              >
                <CloseIcon />
              </Button>
              <Typography
                id="modal-modal-title"
                sx={{ color: `#${bannerData.textColor}` }}
              >
                {bannerData.text}
              </Typography>
            </Box>
          </Box>
        </Modal>
      )}

      {/* <Box
        sx={{
          bgcolor: "rgba(21, 173, 135, 0.1)",
          py: 2,
          px: 3,
          borderRadius: 2,
          mb: 2,
        }}
      >
        <Typography
          variant={isMobile ? "body2" : "h6"}
          textAlign="center"
          sx={{
            color: "text.primary",
            "& a": { color: "brand.main", textDecoration: "none" },
          }}
        >
          Instant Transfer is Back! Re-enjoy the speed and convenience of
          Instant Transfer! 🚀 🎉
        </Typography>
      </Box> */}
      <Grid container spacing={0} sx={{ paddingTop: { xs: 0, md: 3 } }}>
        <Grid item xs={12} lg={8} display="flex" alignItems="center">
          <Box px={{ xs: 0, md: 5 }} py={1}>
            <Typography
              variant="h4"
              align={isMobile ? "center" : "start"}
              color={"brand.main"}
              sx={{
                fontWeight: "bold",
                display: { xs: "none", md: "block" },
              }}
            >
              Transfer Money From USA to India
            </Typography>
            <Typography
              variant="body1"
              sx={{ marginTop: 1, display: { xs: "none", md: "block" } }}
            >
              Absolute transparency & no hidden fees. Experience secure &
              instant delivery of funds direct to the bank account around the
              globe.
            </Typography>
            <Box
              display={"flex"}
              sx={{
                marginTop: 1,
                display: { xs: "none", md: "flex" },
                width: "100%",
              }}
            >
              {/* <Button
                variant="outlined"
                size="large"
                color="brand"
                sx={{
                  p: 2,
                  px: 3,
                  borderRadius: 2,
                  mt: 3,
                  marginInlineEnd: 2,
                }}
                alignItems="center"
                href="https://apps.apple.com/us/app/crobo/id6474628320"
                startIcon={
                  <img
                    src={APP_STORE_ICON}
                    alt="APP STORE ICON"
                    height="40px"
                  />
                }
                onClick={() => {
                  analytics.track("App Store Button Clicked", {
                    category: "Engagement",
                    label: "App Store Link",
                    url: "https://apps.apple.com/us/app/crobo/id6474628320",
                  });
                }}
              >
                <Typography color="grey.900">IOS app</Typography>
              </Button> */}
              {/* <Button
                variant="outlined"
                size="large"
                color="brand"
                startIcon={
                  <img src={PLAY_STORE_ICON} alt="PLAY STORE" height="30px" />
                }
                href="https://play.google.com/store/apps/details?id=io.ionic.CROBO"
                onClick={() => {
                  analytics.track("Play Store Button Clicked", {
                    category: "Engagement",
                    label: "Play Store Link",
                    url: "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                  });
                }}
                sx={{
                  p: 2,
                  px: 3,
                  mt: 3,
                  marginInlineEnd: 2,
                  borderRadius: 2,
                }}
              >
                <Typography color="grey.900">Android app</Typography>
              </Button> */}
              <Button
                variant="outlined"
                size="large"
                color="brand"
                sx={{
                  p: 2,
                  px: 3,
                  mt: 3,
                  display: "none",
                }}
                alignItems="center"
                href="https://apps.apple.com/us/app/crobo/id6474628320"
                startIcon={
                  <img
                    src={PLAY_STORE_ICON}
                    alt="PLAY STORE ICON"
                    height="35px"
                  />
                }
                onClick={() => {
                  analytics.track("App Store Button Clicked", {
                    category: "Engagement",
                    label: "App Store Link",
                    url: "https://apps.apple.com/us/app/crobo/id6474628320",
                  });
                }}
              >
                <Typography color="grey.900">Android APP</Typography>
              </Button>
            </Box>
            <Box></Box>
          </Box>
        </Grid>
        <Grid item xs={12} lg={4} display={"flex"} justifyContent={"center"}>
          <Paper
            style={{
              backgroundColor: "#36454F",
              maxWidth: "600px",
              width: "100%",
            }}
            elevation={3}
            sx={{ px: 2, py: 3, borderRadius: 4, color: "white" }}
          >
            <Typography
              fontWeight="bold"
              variant="h5"
              align="center"
              sx={{ fontWeight: "500", color: "white" }}
            >
              Transfer Money
              <br />
              Instantly
            </Typography>
            <Box>
              <form>
                <Box mb={2}>
                  <FormLabel htmlFor="sourceAmount">
                    <Typography
                      variant="body1"
                      color={"white"}
                      id="sourceAmountLabel"
                    >
                      You Send
                    </Typography>
                  </FormLabel>

                  <TextField
                    id="sourceAmount"
                    aria-labelledby="sourceAmountLabel"
                    hiddenLabel
                    type="text"
                    fullWidth
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                    }}
                    value={sourceAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) || value === ".") {
                        setSourceAmount(value);
                        if (value !== "") {
                          setDestinationAmount(
                            (parseFloat(value) * store.exchangeRate).toFixed(2)
                          );
                        } else {
                          setDestinationAmount("");
                        }
                      }
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <AttachMoneyIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={usFlag}
                            alt="USA Flag"
                            style={{ width: "40px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Box
                  mb={1}
                  py={1}
                  borderTop={1}
                  borderBottom={1}
                  borderColor="grey.500"
                >
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1">Exchange Rate</Typography>
                    <Box display="flex" alignItems="center">
                      <Typography variant="body1">$1 =</Typography>
                      {store.loading ? (
                        <CircularProgress size={20} sx={{ color: "#15ad87" }} />
                      ) : (
                        <Typography marginLeft={0.5} variant="body1">
                          ₹{store.exchangeRate}
                        </Typography>
                      )}
                    </Box>
                  </Box>
                  <Box
                    display="flex"
                    justifyContent="space-between"
                    alignItems="center"
                  >
                    <Typography variant="body1">Transfer fee</Typography>
                    <Typography variant="body1">$0.00</Typography>
                  </Box>
                </Box>
                <Box mb={2}>
                  <FormLabel htmlFor="destinationAmount">
                    <Typography
                      variant="body1"
                      color={"white"}
                      id="destinationAmountLabel"
                    >
                      They Receive
                    </Typography>
                  </FormLabel>
                  <TextField
                    id="destinationAmount"
                    aria-labelledby="destinationAmountLabel"
                    type="text"
                    fullWidth
                    value={destinationAmount}
                    onChange={(e) => {
                      const value = e.target.value;
                      if (!isNaN(value) || value === ".") {
                        setDestinationAmount(value);
                        if (value !== "") {
                          setSourceAmount(
                            (parseFloat(value) / store.exchangeRate).toFixed(2)
                          );
                        } else {
                          setSourceAmount("");
                        }
                      }
                    }}
                    sx={{
                      backgroundColor: "white",
                      borderRadius: 2,
                    }}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <CurrencyRupeeIcon />
                        </InputAdornment>
                      ),
                      endAdornment: (
                        <InputAdornment position="end">
                          <img
                            src={indiaFlag}
                            alt="India Flag"
                            style={{ width: "40px" }}
                          />
                        </InputAdornment>
                      ),
                    }}
                  />
                </Box>
                <Button
                  onClick={handleSignUpClick}
                  type="submit"
                  fullWidth
                  variant="contained"
                  color="brand"
                  size="large"
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "#128d6f",
                    },
                    borderRadius: 2,
                    fontWeight: "300",
                  }}
                >
                  <Typography variant="h6">Start Transfer</Typography>
                </Button>
              </form>
            </Box>

            {/* Conditional rendering of the App Store button for iOS devices */}
            {/* {isIOS && (
              <Box display="flex" justifyContent="center" marginTop={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="brand"
                  alignItems="center"
                  href="https://apps.apple.com/us/app/crobo/id6474628320"
                  startIcon={<AppleIcon />}
                  sx={{
                    backgroundColor: "black",
                    "&:hover": {
                      backgroundColor: "#005BB5",
                    },
                  }}
                  onClick={() => {
                    analytics.track("App Store Button Clicked", {
                      category: "Engagement",
                      label: "App Store Link",
                      url: "https://apps.apple.com/us/app/crobo/id6474628320",
                    });
                  }}
                >
                  <Typography>App Store</Typography>
                </Button>
              </Box>
            )} */}

            {/* { isAndroid && (
              <Box display="flex" justifyContent="center" marginTop={2}>
                <Button
                  variant="contained"
                  size="large"
                  color="brand"
                  alignItems="center"
                  href="https://play.google.com/store/apps/details?id=io.ionic.CROBO"
                  startIcon={<ShopIcon />}
                  sx={{
                    backgroundColor: "black",
                    "&:hover": {
                      backgroundColor: "#005BB5",
                    },
                  }}
                  onClick={() => {
                    analytics.track("Play Store Button Clicked", {
                      category: "Engagement",
                      label: "Play Store Link",
                      url: "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                    });
                  }}
                >
                  <Typography>Play Store</Typography>
                </Button>
              </Box>
            )} */}
          </Paper>
        </Grid>
      </Grid>
      <Box sx={{ my: 5, display: "flex", justifyContent: "center" }}>
        <Paper elevation={0} sx={{ py: 4, bgcolor: "brandLight.main" }}>
          <Typography
            variant="h4"
            color={"brand.main"}
            align="center"
            gutterBottom
          >
            Money Transfer Has Never Been Easier!
          </Typography>
          <Stepper />
        </Paper>
      </Box>
      {!isMobile && (
        <Grid container justifyContent="center">
          <Grid item xs={12}>
            <Typography
              fontWeight="bold"
              variant="h5"
              component="h5"
              align="center"
              gutterBottom
            >
              Why <span style={{ color: "#15ad87" }}>CROBO</span> Is The Right
              Choice ?
            </Typography>
          </Grid>
          <Grid item xs={10} md={10} lg={10} mt={2}>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <Card
                  sx={{
                    backgroundColor: "#36454F",
                    borderRadius: 4,
                    boxShadow: 5,
                  }}
                >
                  <CardContent>
                    <Box display={"flex"} justifyContent={"center"}>
                      <AttachMoneyIcon
                        sx={{
                          justifyContent: "center",
                          fill: "#15ad87",
                          fontSize: 40,
                        }}
                        align="center"
                      />
                    </Box>

                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="white"
                      align="center"
                      gutterBottom
                    >
                      Clear Pricing
                    </Typography>
                    <Typography variant="body1" color="white" align="center">
                      No hidden costs or surprises. Know exactly how much you'll
                      pay upfront.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    backgroundColor: "#36454F",
                    borderRadius: 4,
                    boxShadow: 5,
                  }}
                >
                  <CardContent>
                    <Box display={"flex"} justifyContent={"center"}>
                      <VerifiedUser
                        sx={{
                          justifyContent: "center",
                          fill: "#15ad87",
                          fontSize: 40,
                        }}
                        align="center"
                      />
                    </Box>

                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="white"
                      align="center"
                      gutterBottom
                    >
                      Secure Transactions
                    </Typography>
                    <Typography variant="body1" color="white" align="center">
                      We safeguard your personal information throughout the
                      transaction process.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    backgroundColor: "#36454F",
                    borderRadius: 4,
                    boxShadow: 5,
                  }}
                >
                  <CardContent>
                    <Box display={"flex"} justifyContent={"center"}>
                      <Headphones
                        sx={{
                          justifyContent: "center",
                          fill: "#15ad87",
                          fontSize: 40,
                        }}
                        align="center"
                      />
                    </Box>

                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="white"
                      align="center"
                      gutterBottom
                    >
                      Customer Care Service
                    </Typography>
                    <Typography variant="body1" color="white" align="center">
                      Our support team is just a call away to address your
                      concerns without delay.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card
                  sx={{
                    backgroundColor: "#36454F",
                    borderRadius: 4,
                    boxShadow: 5,
                  }}
                >
                  <CardContent>
                    <Box display={"flex"} justifyContent={"center"}>
                      <CurrencyExchange
                        sx={{
                          justifyContent: "center",
                          fill: "#15ad87",
                          fontSize: 40,
                        }}
                        align="center"
                      />
                    </Box>

                    <Typography
                      variant="h6"
                      fontWeight="bold"
                      color="white"
                      align="center"
                      gutterBottom
                    >
                      Money Refund Guarantee
                    </Typography>
                    <Typography variant="body1" color="white" align="center">
                      We guarantee successful delivery, or your full amount will
                      be refunded.
                    </Typography>
                  </CardContent>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} style={{ textAlign: "center", marginTop: "2rem" }}>
            <Button
              onClick={handleSignUpClick}
              variant="contained"
              color="brand"
              sx={{
                borderRadius: 2,
              }}
              size="large"
            >
              <Typography variant="h5" color={"white"}>
                Start Transfer
              </Typography>
            </Button>
          </Grid>
        </Grid>
      )}
      <Grid container justifyContent="center">
        <Grid item xs={12} lg={6} container justifyContent="center" mt={8}>
          <Box width="75%">
            <img
              src={Image}
              alt="globe"
              style={{ maxWidth: "100%", height: "auto" }}
            />
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          lg={6}
          container
          alignItems="center"
          justifyContent="center"
        >
          <Box p={5} textAlign={{ xs: "center", md: "left" }}>
            <Typography fontWeight="bold" variant="h5" gutterBottom>
              Frequently Asked <br /> Questions
            </Typography>
            <Box py={{ xs: 2, lg: 3 }}>
              <Box mb={3}>
                <Typography variant="body1">
                  How long will the money transfer take?
                </Typography>
                <Typography fontWeight="bold" variant="subtitle1">
                  A FEW SECONDS
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="body1">
                  How much does it cost to transfer USD to INR?
                </Typography>
                <Typography fontWeight="bold" variant="subtitle1">
                  It COSTS NOTHING ($0)
                </Typography>
              </Box>
              <Box mb={3}>
                <Typography variant="body1">Why do I need KYC?</Typography>
                <Typography fontWeight="bold" variant="subtitle1">
                  FOR LEGAL AND REGULATORY PURPOSES
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Box sx={{ maxWidth: "100%", mb: 5 }}>
          <Typography variant="h4" textAlign={"center"} color={"brand.main"}>
            Testimonials
          </Typography>
          <TestimonialsCarousel />
        </Box>
      </Grid>
    </Box>
  );
};

export default HomeContent;
