import React from "react";
import CroboContainer from "../components/croboContainer";
import { 
  Box, 
  Button, 
  Container, 
  IconButton, 
  Typography, 
  Stack,
  Paper,
  Divider 
} from "@mui/material";
import SupportAgentIcon from "@mui/icons-material/SupportAgent";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import EmailIcon from '@mui/icons-material/Email';
import { handleOpenChat, analytics } from "../helper/helper";

export const SupportButton = ({variant}) => {
  return (
    <Button
      onClick={() => {
        handleOpenChat();
        analytics.track("Support Ticket Raised");
      }}
      startIcon={<SupportAgentIcon />}
      variant="contained"
      fullWidth
      size="large"
      sx={{
        py: 1.5,
        bgcolor: "brand.main",
        "&:hover": {
          bgcolor: "brand.dark",
        },
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          color: "white",
        }}
      >
        Chat with Support
      </Typography>
    </Button>
  );
};

const ContactCard = ({ icon: Icon, title, subtitle, onClick }) => (
  <Paper
    elevation={0}
    sx={{
      p: 2.5,
      borderRadius: 3,
      bgcolor: 'grey.50',
      cursor: 'pointer',
      '&:hover': {
        bgcolor: 'grey.100',
      }
    }}
    onClick={onClick}
  >
    <Stack direction="row" spacing={2} alignItems="center">
      <Icon sx={{ fontSize: 28, color: 'brand.main' }} />
      <Box>
        <Typography variant="subtitle1" fontWeight="500">
          {title}
        </Typography>
        <Typography variant="body2" color="text.secondary">
          {subtitle}
        </Typography>
      </Box>
    </Stack>
  </Paper>
);

const SocialButton = ({ href, icon: Icon, label, onClick }) => (
  <IconButton
    component="a"
    href={href}
    target="_blank"
    rel="noopener noreferrer"
    aria-label={label}
    onClick={onClick}
    sx={{
      bgcolor: 'grey.100',
      p: 2,
      '&:hover': {
        bgcolor: 'grey.200',
      }
    }}
  >
    <Icon sx={{ fontSize: 24, color: 'text.primary' }} />
  </IconButton>
);

const Support = () => {
  const openWhatsApp = () => {
    const phoneNumber = "+14254765770";
    const message = "I would like to connect with Crobo support for";
    const whatsappUrl = `https://api.whatsapp.com/send?text=${message}&phone=${phoneNumber}`;
    analytics.track("WhatsApp Support Opened", { phoneNumber, message });
    window.open(whatsappUrl, "_self");
  };

  const copyEmail = () => {
    navigator.clipboard.writeText('care@crobo.money');
    // You might want to add a toast notification here
  };

  return (
    <CroboContainer>
      <Container maxWidth="sm">
        <Stack 
          spacing={4} 
          sx={{ 
            py: 4,
            px: 2
          }}
        >
          {/* Header */}
          <Box textAlign="center">
            <Typography 
              variant="h5" 
              fontWeight="600"
              gutterBottom
            >
              How can we help?
            </Typography>
            <Typography 
              variant="body1" 
              color="text.secondary"
            >
              Choose your preferred way to connect with us
            </Typography>
          </Box>

          {/* Contact Options */}
          <Stack spacing={2}>
            <ContactCard
              icon={SupportAgentIcon}
              title="Live Chat Support"
              subtitle="Get instant help from our team"
              onClick={() => {
                handleOpenChat();
                analytics.track("Support Chat Opened");
              }}
            />
            
            <ContactCard
              icon={WhatsAppIcon}
              title="WhatsApp Support"
              subtitle="Message us on WhatsApp"
              onClick={openWhatsApp}
            />

            <ContactCard
              icon={EmailIcon}
              title="Email Support"
              subtitle="care@crobo.money"
              onClick={copyEmail}
            />
          </Stack>

          {/* Social Links */}
          <Box>
            <Typography 
              variant="subtitle2" 
              color="text.secondary"
              textAlign="center" 
              sx={{ mb: 2 }}
            >
              FOLLOW US ON SOCIAL MEDIA
            </Typography>
            <Stack 
              direction="row" 
              justifyContent="center" 
              spacing={2}
            >
              <SocialButton
                href="https://www.linkedin.com/company/crobo-money"
                icon={LinkedInIcon}
                label="LinkedIn"
                onClick={() => analytics.track("LinkedIn Icon Clicked")}
              />
              <SocialButton
                href="https://www.instagram.com/crobo_money"
                icon={InstagramIcon}
                label="Instagram"
                onClick={() => analytics.track("Instagram Icon Clicked")}
              />
              <SocialButton
                href="https://www.twitter.com/crobo_money"
                icon={TwitterIcon}
                label="Twitter"
                onClick={() => analytics.track("Twitter Icon Clicked")}
              />
            </Stack>
          </Box>

          <Divider sx={{ my: 2 }} />

          {/* Copyright */}
          <Typography 
            variant="body2" 
            textAlign="center"
            color="text.secondary"
          >
            &copy;2024 Crobo. All rights reserved
          </Typography>
        </Stack>
      </Container>
    </CroboContainer>
  );
};

export default Support;
