import { Box, Container, Pagination, Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchTransactions } from "../../reducers/transactionReducer";
import CroboContainer from "../../components/croboContainer";
import TransactionCard from "../../components/transactionCard";
import { Link } from "react-router-dom";
import Loader from "../../components/loader";

const TransactionHistory = () => {
  const { transactions, loading,totalPage } = useSelector((state) => state.transaction);
  const [page, setPage] = React.useState(1);
  
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(fetchTransactions(page));
  }, [dispatch,page]);

  const handleChange = (event, value) => {
    setPage(value);
  };
  return (
    <CroboContainer>
      <Box>
        {loading ? (
          <Box py={3}>
            <Loader />
          </Box>
        ) : transactions.length === 0 ? (
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            sx={{ height: "200px" }}
          >
            <Typography alignItems={"center"} variant="h5">
              No Transactions
            </Typography>
          </Box>
        ) : (
          <Container>
            {transactions.map((t) => (
              <Link
                to={`/transaction/details/${t.trans_id}`}
                key={t.id}
                style={{ textDecoration: "none" }}
              >
                <TransactionCard
                  id={t.id}
                  transMsg={t.trans_msg}
                  tranId={t.trans_id}
                  receiveInBank={t.ReceiveInBank}
                  status={t.status}
                  exchangeRate={t.current_rate}
                  transactionalTime={t.transactional_time}
                  UtrNo={t.utr_no}
                  sourceAmount={t.base_sub_total}
                  receiverName={t.receiverName}
                  nickName={t.receiverUnverifiedName}
                />
              </Link>
            ))}
            <Box display={"flex"} justifyContent={"center"} pt={2} mb={10}>
              <Pagination
                count={totalPage}
                page={page}
                onChange={handleChange}
              />
            </Box>
          </Container>
        )}
      </Box>
    </CroboContainer>
  );
};

export default TransactionHistory;
