// export const cybridRemitUrl = "https://stage-remitbank.crobo.money/";
export const cybridRemitUrl = "https://remitbank.crobo.money/";
export const officialWebsiteUrl = "https://www.crobo.money";
export const personaUrl = "https://withpersona.com/verify?inquiry-id=";
export const sdkRedirectToRoute = "/connect-bank";
export const kycTypes = {
  id_and_selfie: "id_and_selfie",
  ssn: "ssn",
  document_submission: "document_submission",
};
