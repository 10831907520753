import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  FormControlLabel,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Radio,
  RadioGroup,
  Select,
  Typography,
  Alert,
  Paper,
  TextField,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import { fetchSenderUserProfile } from "../../service/api";
import { analytics, errorAnalytics } from "../../helper/helper";
import { resetTransaction } from "../../reducers/transactionReducer";
import BottomBranding from "../../components/bottomBranding";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";

export const CreateTransaction = () => {
  const [sourceAmount, setSourceAmount] = useState("");
  const [destinationAmount, setDestinationAmount] = useState("");
  const [destinationAmountInstant, setDestinationAmountInstant] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const { exchangeRate, instantExchangeRate } = useSelector(
    (state) => state.exchangeRate
  );

  const [error, setError] = useState();
  //set true to stop instant transfer
  const [instantLimitError, setInstantLimitError] = useState(false);
  const [minLimit, setMinLimit] = useState(1);
  const [maxLimit, setMaxLimit] = useState(0);
  const [maxInstant, setMaxInstant] = useState(0);
  const [purpose, setPurpose] = useState("");
  const [freeTextPurpose, setFreeTextPurpose] = useState("");
  const [value, setValue] = useState("");

  const handleChangeInstant = (event) => {
    setValue(event.target.value);
  };

  const selectPurposeOptions = [
    { name: "Own account transfer", value: "Own account transfer" },
    { name: "Family maintainance", value: "Family maintainance" },
    { name: "Gift", value: "Gift" },
    { name: "Payment for services", value: "Payment for services" },
    { name: "Others", value: "Others" },
  ];

  useEffect(() => {
    dispatch(fetchExchangeRate());
    fetchSenderUserProfile().then((r) => {
      setMinLimit(r.Per_transaction_min);
      setMaxLimit(r.Per_transaction_max);
      setMaxInstant(r.Instant);
    });
    dispatch(resetTransaction());
    // dispatch(
    //   fetchUsers({
    //     client_id: "APIGetBitRemitWeb",
    //     country_code: "+91",
    //     phone_number: id,
    //   })
    // );
  }, [dispatch, id]);

  const getFinalPurpose = (purpose) => {
    if (purpose === "Others") return freeTextPurpose;
    return purpose;
  };

  const handleStartTransfer = () => {
    if (!value) {
      setError("Please select a transfer speed");
      errorAnalytics("TRANSFER_SPEED_NOT_SELECTED", {
        error: "Transfer speed not selected",
      });
      return;
    }

    if (sourceAmount < minLimit) {
      setError(`Min Amount should be greater than $${minLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Min limit error`,
        amount: sourceAmount,
      });
    } else if (sourceAmount > maxLimit) {
      setError(`Max Amount should not be greater than $${maxLimit}`);
      errorAnalytics("LIMIT_ERROR", {
        error: `Max limit error`,
        amount: sourceAmount,
      });
    } else if (!getFinalPurpose(purpose)) {
      setError(`Purpose is mandatory`);
      errorAnalytics("PURPOSE_NOT_ENTERED", {
        error: `Purpose is mandatory`,
      });
    } else {
      analytics.track("Transfer Started", {
        sourceAmount,
        destinationAmount,
        transferType: value === "option1" ? "High Value" : "Instant",
      });
      navigate(`/transaction/confirm/${id}/${sourceAmount}`, {
        state: {
          purpose: getFinalPurpose(purpose),
          is_instant: value === "option1" ? false : true,
        },
      });
    }
  };
 
  const checkInstantError = (sourceAmount) => {
    if (sourceAmount > maxInstant) {
      instantLimitError !== true && setInstantLimitError(true);
      setValue("option1");
    } else {
      instantLimitError === true && setInstantLimitError(false);
    }
  };

  // const freePurposeTextBox = () => {
  //   if (purpose === "Others") {
  //     return (
  //       <OutlinedInput
  //         placeholder="Please enter purpose..."
  //         value={freeTextPurpose}
  //         type="number"
  //         sx={{ marginTop: 2, width: "100%" }}
  //         multiline
  //         minRows={4}
  //         onChange={(e) => {
  //           setFreeTextPurpose(e.target.value);
  //         }}
  //         inputProps={{ maxLength: 100 }}
  //       />
  //     );
  //   }
  // };

  return (
    <CroboContainer>
      <Container maxWidth="md">
        {/* Main Form Section */}
        <Box
          sx={{
            borderRadius: 2,
            p: 2,
          }}
        >
          <Typography
            variant="body1"
            textAlign="center"
            color="text.secondary"
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: 1,
              mb: 2,
            }}
          >
            <InfoIcon sx={{ color: "brand.main" }} fontSize="small" />
            Maximum transfer limit: $
            {maxLimit !== undefined && maxLimit !== null ? (
              <Box
                component="span"
                sx={{ color: "brand.main", fontWeight: 600 }}
              >
                {maxLimit}
              </Box>
            ) : (
              <CircularProgress size="14px" color="brand" />
            )}
          </Typography>
          {/* Amount Input */}
          <OutlinedInput
            placeholder="Enter Amount in USD"
            value={sourceAmount}
            startAdornment={
              <InputAdornment position="start">
                <Box
                  component="img"
                  src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                  sx={{
                    height: 24,
                    width: 32,
                    mr: 1,
                    borderRadius: 0.5,
                  }}
                  alt="USD"
                />
              </InputAdornment>
            }
            endAdornment={
              <InputAdornment position="end">
                <Typography color="text.secondary">USD</Typography>
              </InputAdornment>
            }
            sx={{
              bgcolor: "white",
              "& .MuiOutlinedInput-input": {
                py: 2,
                fontSize: "1.1rem",
                fontWeight: 500,
              },
              "&:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: "brand.main",
              },
              "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: "brand.main",
              },
            }}
            type="number"
            inputProps={{
              inputMode: "numeric",
              onWheel: (e) => e.target.blur(),
              min: 0,
            }}
            onChange={(e) => {
              if (e.target.value < 0) return;
              setSourceAmount(e.target.value);
              setDestinationAmount((e.target.value * exchangeRate).toFixed(2));
              setDestinationAmountInstant(
                (e.target.value * instantExchangeRate).toFixed(2)
              );
              checkInstantError(e.target.value);
              analytics.track("Source Amount Entered", {
                sourceAmount: e.target.value,
              });
            }}
            fullWidth
          />

          {/* Transfer Options */}
          <FormControl
            component="fieldset"
            error={!!error && !value}
            sx={{
              mt: 3,
              width: "100%",
            }}
          >
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              Select Transfer Speed
            </Typography>

            <RadioGroup
              value={value}
              onChange={handleChangeInstant}
              sx={{ gap: 2 }}
            >
              {/* Standard Transfer Option */}
              <Paper
                elevation={value === "option1" ? 2 : 0}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: value === "option1" ? "brand.main" : "grey.200",
                  borderRadius: 2,
                  transition: "all 0.2s",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControlLabel
                    value="option1"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "brand.main",
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="subtitle1" fontWeight={500}>
                          High Value Transfer
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Best exchange rate (up to 48 business hours)
                        </Typography>
                      </Box>
                    }
                  />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    sx={{
                      bgcolor: "grey.50",
                      px: 1.5,
                      py: 0.75,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    $1 = ₹{exchangeRate}
                  </Typography>
                </Box>

                {value === "option1" && (
                  <Box sx={{ mt: 2 }}>
                    <OutlinedInput
                      placeholder="Enter Amount in INR"
                      value={destinationAmount}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            sx={{
                              height: 24,
                              width: 32,
                              mr: 1,
                              borderRadius: 0.5,
                            }}
                            alt="INR"
                          />
                          ₹
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">INR</InputAdornment>
                      }
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                        onWheel: (e) => e.target.blur(),
                        min: 0,
                      }}
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        const calculatedSourceAmount = (
                          e.target.value / exchangeRate
                        ).toFixed(2);
                        setSourceAmount(calculatedSourceAmount);
                        setDestinationAmount(e.target.value);
                        checkInstantError(calculatedSourceAmount);
                        analytics.track("Destination Amount Entered", {
                          destinationAmount: e.target.value,
                        });
                      }}
                      fullWidth
                      sx={{
                        mb: 1,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "brand.main",
                        },
                      }}
                    />
                  </Box>
                )}
              </Paper>

              {/* Instant Transfer Option */}
              <Paper
                elevation={value === "option2" ? 2 : 0}
                sx={{
                  p: 2,
                  border: 1,
                  borderColor: value === "option2" ? "brand.main" : "grey.200",
                  borderRadius: 2,
                  transition: "all 0.2s",
                  opacity: instantLimitError ? 0.7 : 1,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    alignItems: "flex-start",
                  }}
                >
                  <FormControlLabel
                    disabled={instantLimitError}
                    value="option2"
                    control={
                      <Radio
                        sx={{
                          "&.Mui-checked": {
                            color: "brand.main",
                          },
                        }}
                      />
                    }
                    label={
                      <Box>
                        <Typography variant="subtitle1" fontWeight={500}>
                          Instant Transfer
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                          Receive within seconds
                        </Typography>
                      </Box>
                    }
                  />
                  <Typography
                    variant="body2"
                    fontWeight="bold"
                    color="text.secondary"
                    sx={{
                      bgcolor: "grey.50",
                      px: 1.5,
                      py: 0.75,
                      borderRadius: 1,
                      display: "flex",
                      alignItems: "center",
                      gap: 0.5,
                    }}
                  >
                    $1 = ₹{instantExchangeRate}
                  </Typography>
                </Box>

                {value === "option2" && !instantLimitError && (
                  <Box sx={{ mt: 2 }}>
                    <OutlinedInput
                      placeholder="Enter Amount in INR"
                      value={destinationAmountInstant}
                      startAdornment={
                        <InputAdornment position="start">
                          <Box
                            component="img"
                            src="https://upload.wikimedia.org/wikipedia/commons/4/41/Flag_of_India.svg"
                            sx={{
                              height: 24,
                              width: 32,
                              mr: 1,
                              borderRadius: 0.5,
                            }}
                            alt="INR"
                          />
                          ₹
                        </InputAdornment>
                      }
                      endAdornment={
                        <InputAdornment position="end">INR</InputAdornment>
                      }
                      type="number"
                      inputProps={{
                        inputMode: "numeric",
                        onWheel: (e) => e.target.blur(),
                        min: 0,
                      }}
                      onChange={(e) => {
                        if (e.target.value < 0) return;
                        const calculatedSourceAmount = (
                          e.target.value / instantExchangeRate
                        ).toFixed(2);

                        setSourceAmount(calculatedSourceAmount);
                        setDestinationAmountInstant(e.target.value);
                        checkInstantError(calculatedSourceAmount);
                        analytics.track("Destination Amount Entered", {
                          destinationAmount: e.target.value,
                        });
                      }}
                      fullWidth
                      sx={{
                        mb: 1,
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "brand.main",
                        },
                      }}
                    />
                  </Box>
                )}
              </Paper>
            </RadioGroup>

            {/* Error message for transfer speed */}
            {error && !value && (
              <Typography
                color="error"
                variant="body2"
                sx={{
                  mt: 1,
                  display: "flex",
                  alignItems: "center",
                  gap: 0.5,
                }}
              >
                <ErrorOutlineIcon fontSize="small" />
                Please select a transfer speed
              </Typography>
            )}
          </FormControl>

          {instantLimitError && (
            <Alert severity="error" sx={{ mt: 2 }}>
              Instant transfer is currently unavailable. We are working on it.
              Apologies for the inconvenience
              {/* Not eligible for instant transfer. Maximum limit is ${maxInstant} */}
            </Alert>
          )}

          {/* Purpose Selection */}
          <Box sx={{ mt: 3 }}>
            <Typography
              variant="subtitle1"
              color="text.secondary"
              sx={{ mb: 2 }}
            >
              Purpose of Transfer
            </Typography>

            <Select
              value={purpose}
              displayEmpty
              onChange={(e) => {
                setPurpose(e.target.value);
                analytics.track("Purpose Selected", {
                  purpose: e.target.value,
                });
              }}
              sx={{
                width: "100%",
                bgcolor: "white",
                "& .MuiSelect-select": {
                  py: 1.5,
                },
              }}
            >
              <MenuItem disabled value="">
                <Typography color="text.secondary">Select Purpose</Typography>
              </MenuItem>
              {selectPurposeOptions.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.name}
                </MenuItem>
              ))}
            </Select>

            {purpose === "Others" && (
              <TextField
                multiline
                rows={4}
                placeholder="Please specify the purpose..."
                value={freeTextPurpose}
                onChange={(e) => setFreeTextPurpose(e.target.value)}
                sx={{ mt: 2, width: "100%" }}
                inputProps={{ maxLength: 100 }}
              />
            )}
          </Box>

          {error && (
            <Alert severity="error" sx={{ mt: 2 }}>
              {error}
            </Alert>
          )}

          {/* Add validation message when no option is selected */}
          {sourceAmount && !value && (
            <Typography
              color="error"
              variant="body2"
              sx={{ mt: 1, display: "flex", alignItems: "center", gap: 0.5 }}
            >
              <ErrorOutlineIcon fontSize="small" />
              Please select a transfer speed
            </Typography>
          )}

          {/* Submit Button */}
          <Button
            variant="contained"
            fullWidth
            size="large"
            onClick={handleStartTransfer}
            disabled={
              !sourceAmount ||
              sourceAmount === 0 ||
              !value ||
              (value === "option1" &&
                (!destinationAmount || destinationAmount === 0)) ||
              (value === "option2" &&
                (!destinationAmountInstant || destinationAmountInstant === 0))
            }
            sx={{
              mt: 3,
              py: 2,
              bgcolor: "brand.main",
              "&:hover": {
                bgcolor: "brand.dark",
              },
              "&:disabled": {
                bgcolor: "grey.300",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                color: "white",
              }}
            >
              Continue to Transfer
            </Typography>
          </Button>
        </Box>
        <BottomBranding />
      </Container>
    </CroboContainer>
  );
};
