// src/VerificationStatus.js
import React from 'react';
import { List, ListItem, ListItemText, ListItemIcon } from '@mui/material';
import { CheckCircle, AccessTime, CloseRounded } from '@mui/icons-material';

const successColor = 'green';
const errorColor = 'red';

const VerificationStatus = ({ state }) => {
  const getBankMessage = () => {
    const { bank, bankVerification, bankBypass } = state;
    if (bank === 1 || bankBypass) {
      return bankVerification === 3
        ? 'Bank verification failed.'
        : 'Bank verification complete.';
    } else if (bank === 2) {
      return 'Bank verification is in progress..';
    } else if (bank === 4) {
      return 'You need to reconnect your bank.';
    } else if (bank === 0) {
      return 'Bank verification has not started yet.';
    } else {
      return 'Bank verification failed.';
    }
  };

  const getBankIcon = () => {
    const { bank, bankVerification, bankBypass } = state;
    if (bank === 1 || bankBypass) {
      return bankVerification === 3 ? CloseRounded : CheckCircle;
    } else if (bank === 3) {
      return CloseRounded;
    } else {
      return AccessTime;
    }
  };

  const getBankIconColor = () => {
    const { bank, bankVerification, bankBypass } = state;
    if (bank === 1 || bankBypass) {
      return bankVerification === 3 ? errorColor : successColor;
    } else if (bank === 3) {
      return errorColor;
    } else {
      return errorColor;
    }
  };

  const getKycMessage = () => {
    const { kyc, bankVerification,  } = state;
    if (kyc === 1) {
      return bankVerification === 0 || bankVerification === 2
        ? 'ID verification is in review.'
        : 'ID verification complete.';
    } else if (kyc === 2) {
      return 'ID verification is in progress..';
    } else if (kyc === 0) {
      return 'ID verification has not started yet.';
    } else if (kyc === 4) {
      return 'ID verification is in review.';
    } 
    else {
      return 'ID verification failed.';
    }
  };

  const getKycIcon = () => {
    const { kyc, bankVerification } = state;
    if (kyc === 1) {
      return bankVerification === 0 || bankVerification === 2 ? AccessTime : CheckCircle;
    } else if (kyc === 3) {
      return CloseRounded;
    } else {
      return AccessTime;
    }
  };

  const getKycIconColor = () => {
    const { kyc, bankVerification } = state;
    if (kyc === 1) {
      return bankVerification === 0 || bankVerification === 2 ? errorColor : successColor;
    } else if (kyc === 3) {
      return errorColor;
    } else {
      return errorColor;
    }
  };

  return (
    <List>
      <ListItem >
        <ListItemIcon>
          {React.createElement(getBankIcon(), { color: getBankIconColor() })}
        </ListItemIcon>
        <ListItemText primary="Bank Verification" secondary={!state.loading? getBankMessage(): getBankMessage() + "  Fetching updated data..."} />
      </ListItem>
      <ListItem>
        <ListItemIcon>
          {React.createElement(getKycIcon(), { color: getKycIconColor() })}
        </ListItemIcon>
        <ListItemText primary="ID Verification" secondary={!state.loading? getKycMessage(): getKycMessage() + "  Fetching updated data..."} />
      </ListItem>
    </List>
  );
};

export default VerificationStatus;
