import { usePlaidLink } from "react-plaid-link";
import { useEffect, useState } from "react";
import axios from "axios";
import { backend } from "../../reducers/userReducer";
import { useSelector } from "react-redux";
import { analytics } from "../../helper/helper";

// Helper function to create an external bank account

// Custom Hook to handle Plaid functionality
export const usePlaid = ({
  setBankBypass,
  finalPolling,
  setInternalLoader,
  setIsPolling,
}) => {
  const { kyc } = useSelector((state) => state.userAuth);
  async function createExternalBankAccount({
    name,
    account_kind,
    customer_guid,
    asset,
    plaid_public_token,
    plaid_account_id,
    plaid_processor_token,
    plaid_institution_id,
    plaid_account_mask,
    plaid_account_name,
  }) {
    const url = `${backend}/users/createExternalBankAccount`;

    const requestBody = {
      name,
      account_kind,
      customer_guid,
      asset,
      plaid_public_token,
      plaid_account_id,
      plaid_processor_token,
      plaid_institution_id,
      plaid_account_mask,
      plaid_account_name,
    };
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: {
          "x-auth-token": token,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestBody),
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (kyc) {
        finalPolling(true);
      } else {
        setBankBypass(true);
      }
      setInternalLoader(false);

      // Track successful bank account creation
      analytics.track("PLAID Bank Account Created", {
        bankName: name,
        result: result,
        accountKind: account_kind,
        customerGuid: customer_guid,
        asset,
      });
    } catch (error) {
      console.error("Error creating external bank account:", error);

      // Track error event
      analytics.track("PLAID Error Creating Bank Account", {
        error: error.message,
      });
    }
  }
  const [linkToken, setLinkToken] = useState(null);

  // Generate the Plaid link token from your backend
  const generateLinkToken = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await axios.get(
        `${backend}/users/generatePlaidLinkToken`,
        {
          headers: {
            "x-auth-token": token,
          },
        }
      );
      setLinkToken(response.data.plaid_link_token);

      // Track link token generation event
      analytics.track("PLAID Link Token Generated", { success: true });
    } catch (error) {
      console.error("Error generating Plaid link token:", error);

      // Track error in generating link token
      analytics.track("PLAID Error Generating Link Token", {
        error: error.message,
      });
    }
  };

  useEffect(() => {
    generateLinkToken();
  }, []);

  // Use Plaid Link
  const { open, ready, error } = usePlaidLink({
    token: linkToken,
    onSuccess: async (publicToken, metadata) => {
      const guid = localStorage.getItem("guid");

      // Track success event
      analytics.track("PLAID Link Success", {
        institution: metadata.institution.name,
        accounts: metadata.accounts.map((account) => ({
          id: account.id,
          mask: account.mask,
          name: account.name,
        })),
      });

      try {
        const accountDetails = {
          name: metadata.institution.name,
          account_kind: "plaid",
          customer_guid: guid,
          asset: "USD",
          plaid_public_token: metadata.public_token,
          plaid_account_id: metadata.accounts[0]?.id || "",
          plaid_processor_token: "",
          plaid_institution_id: metadata.institution?.institution_id,
          plaid_account_mask: metadata.accounts[0]?.mask || "",
          plaid_account_name: metadata.accounts[0]?.name || "Bank Account",
        };

        await createExternalBankAccount(accountDetails);

        // Track after account details are created
        analytics.track("PLAID External Bank Account Details Submitted", {
          institution: accountDetails.name,
          accountId: accountDetails.plaid_account_id,
        });
      } catch (error) {
        console.error("Error during the Plaid link success flow:", error);
        // Track error event
        analytics.track("PLAID Error in Success Flow", {
          error: error.message,
        });
      }
    },
    onExit: (err, metadata) => {
      if (err) {
        console.error("Plaid exit with error:", err);
        setInternalLoader(false);
        setIsPolling(false);
        // Track error during Plaid exit
        analytics.track("PLAID Link Exit With Error", { error: err.message });
      } else {
        // Track user-initiated exit event
        setInternalLoader(false);
        analytics.track("PLAID Link Exit", { metadata });
      }
    },
  });

  // Return necessary values to be used in other components
  return {
    open,
    ready,
    error,
  };
};
