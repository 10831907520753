import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getRecipients } from "../../reducers/userReducer";
import CroboContainer from "../../components/croboContainer";
import { Box, CircularProgress, Container, Typography } from "@mui/material";
import RecipientsCard from "../../components/recipientsCard";
import { useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const ManageRecipients = () => {
    const store = useSelector((state) => {
        return state.userAuth
    });
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(getRecipients());
    }, [dispatch]);

    const navigate = useNavigate();

    const onClickEdit = (props) => {
        navigate(`/edit-recipient`, {
            state: props
        })
    }

    const recipientDetails = (recipient, index) => {
        try {
            const bankDetails = JSON.parse(recipient.bank_details);
            return <RecipientsCard
                key={recipient.id}
                id={recipient.id}
                name={recipient.unverified_user_name}
                phone={`${recipient.phone}`}
                bank_account_number={bankDetails.bank_account_number}
                bank_name={bankDetails.bank_name}
                ifsc={bankDetails.ifsc_code}
                onClickEdit={onClickEdit}
                pan={recipient.pan_number}
                email={recipient.unverified_email}
                is_bank_added={recipient.is_bank_added}
            />
        } catch (error) {
            console.log('Error: ', error);
        }
    }

    return (
        <CroboContainer>
            <Box>
                    {store.loading ? (
                        <Box display={"flex"} justifyContent={"center"}>
                            <CircularProgress color="brand" />
                        </Box>
                    ) : (
                        store.recipients?.length >= 1 ? (
                            <Container>
                                <Typography variant="h5" sx={{mt:2}}>Manage Recipient</Typography>
                                {store.recipients?.map(recipientDetails)}
                            </Container>
                        ) : (
                            <Box display={"flex"} justifyContent={"center"} alignItems={'center'} sx={{ height: "200px" }}>
                                <Typography alignItems={"center"} variant="h5">No Recipients</Typography>
                            </Box>
                        )
                    )}
            </Box>
            
            <ToastContainer
                    hideProgressBar
                    position="top-center"
                />
        </CroboContainer>
    );
};

export default ManageRecipients;