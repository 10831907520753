import React from "react";
import { Box, Typography, Link, Button, IconButton } from "@mui/material";
import HeadsetMicIcon from "@mui/icons-material/HeadsetMic";
import EmailIcon from "@mui/icons-material/Email";
import brandLogo from "../assets/Crobo_Full.png";
import { analytics, handleOpenChat } from "../helper/helper";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import HelpIcon from "@mui/icons-material/Help";
import InfoIcon from "@mui/icons-material/Info";
import APP_STORE_ICON from "../assets/appStore.svg";
import PLAY_STORE_ICON from "../assets/playStore.svg";

const Footer = () => {
  return (
    <Box
      component="footer"
      sx={{
        backgroundColor: "#36454F",
        borderTop: "4px solid #15ad87",
        position: "relative",
      }}
    >
      {/* Main Footer Content */}
      <Box
        sx={{
          maxWidth: "1200px",
          margin: "0 auto",
          px: { xs: 3, sm: 4, md: 5 },
          py: { xs: 4, md: 6 },
        }}
      >
        <Box
          sx={{
            display: "grid",
            gridTemplateColumns: {
              xs: "1fr",
              sm: "1fr 1fr",
              md: "2fr 1fr 1fr",
            },
            gap: { xs: 4, sm: 6, md: 8 },
          }}
        >
          {/* Brand Column */}
          <Box>
            <img
              src={brandLogo}
              alt="CROBO Logo"
              style={{
                width: "180px",
                marginBottom: "1.5rem",
              }}
            />
            <Typography variant="body1" color="grey.300" sx={{ mb: 2 }}>
              Transfer money securely and instantly from USA to India
            </Typography>
            <Typography
              variant="h6"
              color="white"
              sx={{
                mb: 2,
                fontSize: "1rem",
                fontWeight: 500,
              }}
            >
              Available on
            </Typography>
            {/* <Button
              variant="outlined"
              sx={{
                py: 1,
                px: 2,
                borderColor: "rgba(255,255,255,0.3)",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255,255,255,0.1)",
                },
                transition: "all 0.2s ease-in-out",
              }}
              href="https://apps.apple.com/us/app/crobo/id6474628320"
              startIcon={
                <img src={APP_STORE_ICON} alt="APP STORE" height="32px" />
              }
              onClick={() => {
                analytics.track("App Store Button Clicked", {
                  category: "Engagement",
                  label: "App Store Link",
                  url: "https://apps.apple.com/us/app/crobo/id6474628320",
                });
              }}
            >
              <Typography color="white">Download iOS App</Typography>
            </Button> */}

            {/* <Button
              variant="outlined"
              sx={{
                py: 1,
                px: 2,
                mt: 2,
                borderColor: "rgba(255,255,255,0.3)",
                "&:hover": {
                  borderColor: "white",
                  backgroundColor: "rgba(255,255,255,0.1)",
                },
                transition: "all 0.2s ease-in-out",
              }}
              href="https://play.google.com/store/apps/details?id=io.ionic.CROBO"
              startIcon={
                <img
                  src={PLAY_STORE_ICON}
                  alt="PLAY STORE ICON"
                  height="35px"
                />
              }
              onClick={() => {
                analytics.track("App Store Button Clicked", {
                  category: "Engagement",
                  label: "App Store Link",
                  url: "https://play.google.com/store/apps/details?id=io.ionic.CROBO",
                });
              }}
            >
              <Typography color="white">Download Android APP</Typography>
            </Button> */}
          </Box>

          {/* Social Links Column */}
          <Box>
            <Typography
              variant="h6"
              color="white"
              sx={{
                mb: 3,
                fontSize: "1.1rem",
                fontWeight: 600,
                position: "relative",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  bottom: -8,
                  left: 0,
                  width: 40,
                  height: 2,
                  backgroundColor: "#15ad87",
                },
              }}
            >
              Connect With Us
            </Typography>
            <Box
              sx={{
                display: "flex",
                gap: 1,
                mb: 3,
              }}
            >
              {[
                {
                  icon: LinkedInIcon,
                  url: "https://www.linkedin.com/company/crobo-money",
                  label: "LinkedIn",
                },
                {
                  icon: InstagramIcon,
                  url: "https://www.instagram.com/crobo_money",
                  label: "Instagram",
                },
                {
                  icon: TwitterIcon,
                  url: "https://www.twitter.com/crobo_money",
                  label: "Twitter",
                },
              ].map((social) => (
                <IconButton
                  key={social.label}
                  component="a"
                  href={social.url}
                  target="_blank"
                  aria-label={social.label}
                  sx={{
                    color: "white",
                    "&:hover": {
                      backgroundColor: "rgba(255,255,255,0.1)",
                      transform: "translateY(-2px)",
                    },
                    transition: "all 0.2s ease-in-out",
                  }}
                >
                  <social.icon sx={{ fontSize: 28 }} />
                </IconButton>
              ))}
            </Box>
            <Box sx={{ display: "flex", alignItems: "center" }}>
              <InfoIcon sx={{ color: "grey.400", fontSize: 20, mr: 1 }} />
              <Link
                href="/about-us"
                sx={{
                  color: "grey.300",
                  textDecoration: "none",
                  "&:hover": {
                    color: "white",
                    textDecoration: "underline",
                  },
                }}
              >
                <Typography variant="h6">About Us</Typography>
              </Link>
            </Box>
          </Box>

          {/* Customer Care Column */}
          <Box>
            <Typography
              variant="h6"
              color="white"
              sx={{
                mb: 3,
                fontSize: "1.1rem",
                fontWeight: 600,
                position: "relative",
                "&:after": {
                  content: '""',
                  position: "absolute",
                  bottom: -8,
                  left: 0,
                  width: 40,
                  height: 2,
                  backgroundColor: "#15ad87",
                },
              }}
            >
              Customer Care
            </Typography>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
              }}
            >
              {[
                {
                  icon: HeadsetMicIcon,
                  text: "Contact Support",
                  action: handleOpenChat,
                  isButton: true,
                },
                {
                  icon: EmailIcon,
                  text: "care@crobo.money",
                  href: "mailto:care@crobo.money",
                },
                {
                  icon: HelpIcon,
                  text: "Support Center",
                  href: "/support",
                },
              ].map((item, index) => (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: 1,
                  }}
                >
                  <item.icon
                    sx={{
                      color: "grey.400",
                      fontSize: 20,
                    }}
                  />
                  {item.isButton ? (
                    <Button
                      onClick={item.action}
                      sx={{
                        color: "grey.300",
                        p: 0,
                        minWidth: "auto",
                        "&:hover": {
                          color: "white",
                          backgroundColor: "transparent",
                        },
                      }}
                    >
                      {item.text}
                    </Button>
                  ) : (
                    <Link
                      href={item.href}
                      sx={{
                        color: "grey.300",
                        textDecoration: "none",
                        "&:hover": {
                          color: "white",
                          textDecoration: "underline",
                        },
                      }}
                    >
                      <Typography variant="body1">{item.text}</Typography>
                    </Link>
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Copyright Bar */}
      <Box
        sx={{
          borderTop: "1px solid rgba(255,255,255,0.1)",
          py: 2,
          px: { xs: 3, sm: 4, md: 5 },
          textAlign: "center",
        }}
      >
        <Typography variant="body2" color="grey.400">
          © {new Date().getFullYear()} Crobo. All rights reserved.
        </Typography>
      </Box>
    </Box>
  );
};

export default Footer;
