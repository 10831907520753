import { Avatar, Box, Stack, Typography } from "@mui/material";
import React from "react";
import { amountRoundOff, convertToDate } from "../helper/helper";
import CloseIcon from "@mui/icons-material/Close";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { Paper } from "@mui/material";
import ReceiptIcon from "@mui/icons-material/Receipt";
import TagIcon from "@mui/icons-material/Tag";
import PauseIcon from "@mui/icons-material/Pause";
import PaymentIcon from "@mui/icons-material/Payment";

const TransactionCard = ({
  id,
  status,
  exchangeRate,
  UtrNo,
  receiverName,
  receiveInBank,
  tranId,
  transactionalTime,
  sourceAmount,
  onClick,
  nickName,
}) => {
  const getStatusConfig = (status, receiveInBank) => {
    if (status === 2) {
      return {
        color: "#d32f2f",
        message: "Failed",
        icon: <CloseIcon />,
        bgColor: "#ffcdd2",
        borderColor: "error.light",
      };
    }
    if ((status === 1 && receiveInBank === 0) || status === 0) {
      return {
        color: "#ffd600",
        message: "Processing",
        icon: <PauseIcon />,
        bgColor: "#ffff8d",
        borderColor: "#fdd835",
      };
    }
    return {
      color: "#2e7d32",
      message: "Success",
      icon: <CheckCircleIcon />,
      bgColor: "#ccff90",
      borderColor: "success.light",
    };
  };

  const statusConfig = getStatusConfig(status, receiveInBank);

  return (
    <Paper
      elevation={0}
      sx={{
        p: 2,
        borderRadius: 2,
        my: 1.5,
        border: '1px solid',
        borderColor: 'grey.200',
        cursor: 'pointer',
        transition: 'all 0.2s ease-in-out',
        '&:hover': {
          boxShadow: 3,
        },
      }}
      onClick={onClick}
    >
      {/* Top Row - Status and Amount */}
      <Box 
        sx={{ 
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
          mb: 1.5
        }}
      >
        {/* Status */}
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Avatar
            sx={{
              bgcolor: statusConfig.bgColor,
              color: `${statusConfig.color}`,
              width: 32,
              height: 32
            }}
          >
            {statusConfig.icon}
          </Avatar>
          <Box>
            <Typography
              variant="h6"

              fontWeight={600}
             
            >
              {nickName || statusConfig.message}
            </Typography>
            <Typography variant="caption" color="text.secondary">
              {convertToDate(transactionalTime)}
            </Typography>
          </Box>
        </Box>

        {/* Amount */}
        <Box 
          sx={{ 
            px: 1.5,
            py: 0.5,
            borderRadius: 1.5,
            display: 'flex',
            alignItems: 'center',
            gap: 0.5
          }}
        >
          <Typography
            variant="h4"
            fontWeight={600}
            color={`${statusConfig.color}`}
          >
            ${amountRoundOff(sourceAmount)}
          </Typography>
        </Box>
      </Box>

      {/* Bottom Row - Transaction Details */}
      <Stack spacing={0.5}>
        {/* Exchange Rate */}
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <PaymentIcon 
            sx={{ 
              fontSize: 16,
              color: 'text.secondary'
            }}
          />
          <Typography variant="body2" color="text.secondary">
            $1 = ₹{amountRoundOff(exchangeRate)}
          </Typography>
        </Box>

        {/* UTR Number if exists */}
        {UtrNo && (
          <Box 
            sx={{ 
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <ReceiptIcon 
              sx={{ 
                fontSize: 16,
                color: 'text.secondary'
              }}
            />
            <Typography variant="body2" color="text.secondary">
              UTR: {UtrNo}
            </Typography>
          </Box>
        )}

        {/* Transaction ID */}
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <TagIcon 
            sx={{ 
              fontSize: 16,
              color: 'text.secondary'
            }}
          />
          <Typography variant="body2" color="text.secondary">
            Transaction ID: {tranId}
          </Typography>
        </Box>
      </Stack>
    </Paper>
  );
};

export default TransactionCard;
