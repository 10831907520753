import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Container,
  FormControl,
  InputAdornment,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import PersonIcon from "@mui/icons-material/Person";
import EmailIcon from "@mui/icons-material/Email";
import { useDispatch, useSelector } from "react-redux";
import { getBanks } from "../../reducers/resourceReducer";
import CodeIcon from "@mui/icons-material/Code";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PeopleIcon from '@mui/icons-material/People';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import {
  callPreregister,
  resetPreregister,
} from "../../reducers/preRegisterReducer";
import { useNavigate } from "react-router-dom";
import { analytics } from "../../helper/helper";
import { callFetchUsers } from "../../reducers/userReducer";

const randomPhoneGenerator = (senderPhone) => {
  const number = Math.floor(Math.random() * 90000) + 10000;
  return senderPhone.substring(7) + number;
}

const phonePrefix = (prefix, phone) => {
  if (!prefix) return phone;
  return `${prefix}-${phone}`;
}

const RecipientDetails = () => {
  const [bankScreen, setBankScreen] = useState(false);
  const [bankName, setBankName] = useState("");
  const [bankAccountNumber, setBankAccountNumber] = useState("");
  const [confirmBankAccountNumber, setConfirmBankAccountNumber] = useState("");
  const [IFSC, setIFSC] = useState("");
  const [name, setName] = useState("");
  const [accountHolderName, setAccountHolderName] = useState("");
  const [email, setEmail] = useState("");
  const [relationship, setRelationship] = useState("");
  const dispatch = useDispatch();
  const [error, setError] = useState(null);
  const { bankList } = useSelector((state) => state.resource);
  const [phone, setPhone] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const { preRegisterDetails } = useSelector((state) => state.preRegister);
  const profile = localStorage.getItem('profile');
  const userId = JSON.parse(profile || {}).user_id;
  const senderPhone = localStorage.getItem('phone');

  useEffect(() => {
    dispatch(resetPreregister());
    // if (preRegisterDetails) {
    //   navigate(`/transaction/create/${phonePrefix(userId, phone)}`);
    // }
    dispatch(getBanks());
  }, [dispatch, preRegisterDetails, phone, navigate]);

  const validateEmail = (email) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(String(email).toLowerCase());
  };
  const handleRecipient = async () => {
    if (
      !bankName ||
      !bankAccountNumber ||
      !confirmBankAccountNumber ||
      !IFSC ||
      !name
    ) {
      setError("All fields are compulsory");
      analytics.track("Recipient Bank Details Submission Failed", {
        reason: "Missing Fields",
      }); // Track failure due to missing fields
      return;
    }
    if (bankAccountNumber !== confirmBankAccountNumber) {
      setError(
        "Bank Account Number and Confirm Bank Account Number do not match"
      );
      analytics.track("Recipient Bank Details Submission Failed", {
        reason: "Account Number Mismatch",
      }); // Track failure due to mismatch
      return;
    }

    if (!bankName) {
      setError("Please Select Bank");
      analytics.track("Recipient Bank Details Submission Failed", {
        reason: "No Bank Selected",
      }); // Track failure due to missing bank
      return;
    }
    setError(null);

    analytics.track("Recipient Bank Details Submitted", {
      name,
      email,
      bankName,
      bankAccountNumber,
      IFSC,
    }); // Track successful bank details submission

    // dispatch(
    //   preRegister({
    //     phone_number: phonePrefix(userId, phone || randomPhoneGenerator(senderPhone)),
    //     user_name: name,
    //     client_id: "APIGetBitRemitWeb",
    //     country_code: "91",
    //     email: email,
    //     bank_details: {
    //       bank_account_number: bankAccountNumber,
    //       bank_name: bankName,
    //       ifsc_code: IFSC,
    //       account_holder_name: accountHolderName,
    //     },
    //     relationship
    //   })
    // );
    try {
      const prefixedPhone = phonePrefix(userId, phone || randomPhoneGenerator(senderPhone));
      const preRegisterResponse = await callPreregister({
        phone_number: prefixedPhone,
        user_name: name,
        client_id: "APIGetBitRemitWeb",
        country_code: "91",
        email: email,
        bank_details: {
          bank_account_number: bankAccountNumber,
          bank_name: bankName,
          ifsc_code: IFSC,
          account_holder_name: accountHolderName,
        },
        relationship
      });
      analytics.track("Preregister Response", preRegisterResponse);
      navigate(`/transaction/create/${prefixedPhone}`);
    } catch (error) {
      analytics.track("Preregister Error", error);
      navigate(`/transaction`);
    }
  };

  const handleProceedToBankDetails = async () => {
    if (!name) {
      setError("Name is required");
      analytics.track("Recipient Name Submission Failed", {
        reason: "Missing Name",
      }); // Track failure due to missing name
      return;
    }
    if (!accountHolderName) {
      setError("Account Holder Name is required");
      analytics.track("Recipient Account Holder Name Submission Failed", {
        reason: "Missing Account Holder Name",
      }); // Track failure due to missing Account Holder Name
      return;
    }
    if (!email) {
      setError("Email is required");
      analytics.track("Recipient Email Submission Failed", {
        reason: "Missing Email",
      }); // Track failure due to missing email
      return;
    }
    if (!validateEmail(email)) {
      setError("Invalid email address");
      analytics.track("Recipient Email Submission Failed", {
        reason: "Invalid Email",
      }); // Track failure due to invalid email
      return;
    }
    let receiverPhone = phone;
    if (!receiverPhone) {
      setError(null);
      receiverPhone = randomPhoneGenerator(senderPhone);
      analytics.track("Transfer Started without Phone", { receiverPhone }); // Track transfer start without phone number
    } else {
      if (receiverPhone.length !== 10 || isNaN(receiverPhone)) {
        setError("Invalid phone number. It should be exactly 10 digits.");
        analytics.track("Invalid Phone Number Entered", { receiverPhone }); // Track invalid phone number
        return;
      } else if (receiverPhone === senderPhone.slice(2)) {
        setError("Enter a valid Indian number");
        return;
      } else if (error) {
        setError(null);
      }
    }
    if (!relationship) {
      setError("Relationship is mandatory");
      analytics.track("Recipient Relationship Submission Failed", {
        reason: "Invalid Relationship",
      }); // Track failure due to invalid phone
      return;
    }
    try {
      const prefixedPhone = phonePrefix(userId, receiverPhone);
      setLoading(true);
      const { data } = await callFetchUsers({
        client_id: "APIGetBitRemitWeb",
        country_code: "+91",
        phone_number: prefixedPhone,
      });
      if (data.status_code === 200) {
        navigate(`/transaction/create/${prefixedPhone}`);
        analytics.track("Existing User Found, Redirect to Create Transaction", {
          phone,
        });
      } else {
        setError(null);
        setLoading(false);
        analytics.track("Recipient Details Proceeded to Bank Details", {
          name,
          email,
        }); // Track proceeding to bank details
        setBankScreen(true);
      }
    } catch (error) {
      analytics.track("Error in Fetching User", { phone, error }); // Track any error in fetching user data
    }
  };

  return (
    <CroboContainer>
      <Container>
        {bankScreen ? (
          <Box
            paddingTop={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ paddingBottom: 2, width: "100%" }} variant="h5">
              Enter recipient bank details
            </Typography>
            {error && (
              <Typography color={"red"} textAlign={"center"}>
                {error}
              </Typography>
            )}
            <FormControl sx={{ width: "100%" ,bgcolor:'white'}}>
              <Autocomplete
                disablePortal
                options={bankList}
                onChange={(event, newValue) => {
                  setBankName(newValue);
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Bank Name" />
                )}
              />
            </FormControl>
            <OutlinedInput
              placeholder="Enter Account Number"
              value={bankAccountNumber}
              type="password"
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              onChange={(e) => setBankAccountNumber(e.target.value)}
            />
            <OutlinedInput
              placeholder="Confirm Account Number"
              value={confirmBankAccountNumber}
              s
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">
                  <AccountBalanceIcon />
                </InputAdornment>
              }
              onChange={(e) => setConfirmBankAccountNumber(e.target.value)}
            />
            <OutlinedInput
              placeholder="IFSC Code"
              value={IFSC}
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">
                  <CodeIcon />
                </InputAdornment>
              }
              onChange={(e) => setIFSC(e.target.value)}
            />
            <Box width={"100%"}>
              <Typography marginTop={2}>
                Only NRO, Savings and Current accounts are supported. <br /> NRE
                is not supported.
              </Typography>
            </Box>
            <Button
              color="brand"
              variant="contained"
              sx={{ marginTop: 2, width: "100%" }}
              size="large"
              onClick={handleRecipient}
            >
              <Typography variant="h6" color={"black"}>
                Proceed
              </Typography>
            </Button>
          </Box>
        ) : (
          <Box
            paddingTop={4}
            display={"flex"}
            flexDirection={"column"}
            alignItems={"center"}
            justifyContent={"center"}
          >
            <Typography sx={{ paddingBottom: 0, width: "100%" }} variant="h5">
              Enter Recipient Details
            </Typography>
            {error && (
              <Typography color={"red"} textAlign={"center"}>
                {error}
              </Typography>
            )}
            <OutlinedInput
              placeholder="Account holder name"
              value={accountHolderName}
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
              endAdornment={
                <Tooltip title="Name at bank" placement="top">
                  <InfoIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              }
              onChange={(e) => setAccountHolderName(e.target.value)}
            />
            <OutlinedInput
              placeholder="Nickname"
              value={name}
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">
                  <PersonIcon />
                </InputAdornment>
              }
              endAdornment={
                <Tooltip
                  title="Special name given to recipient"
                  placement="top"
                >
                  <InfoIcon
                    sx={{
                      cursor: "pointer",
                    }}
                  />
                </Tooltip>
              }
              onChange={(e) => setName(e.target.value)}
            />
            <OutlinedInput
              placeholder="Email"
              value={email}
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">
                  <EmailIcon />
                </InputAdornment>
              }
              onChange={(e) => setEmail(e.target.value)}
            />
            <OutlinedInput
              placeholder="Phone (Optional)"
              value={phone}
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              startAdornment={
                <InputAdornment position="start">+91</InputAdornment>
              }
              onChange={(e) => {
                setPhone(e.target.value);
                if (e.target.value === senderPhone.slice(2)) {
                  setError("Enter a valid Indian number");
                  analytics.track("Invalid Indian Phone Number Entered", {
                    phone: e.target.value,
                  }); // Track invalid Indian phone number
                } else if (error) {
                  setError(null);
                }
              }}
            />
            <Select
              id="relationship"
              value={relationship}
              sx={{ marginTop: 2, width: "100%", bgcolor: "white" }}
              displayEmpty
              startAdornment={
                <InputAdornment position="start">
                  <PeopleIcon />
                </InputAdornment>
              }
              onChange={(e) => setRelationship(e.target.value)}
              renderValue={(selected) => {
                if (selected === "") {
                  return <>Select Relationship</>; // Placeholder text
                }
                return selected;
              }}
            >
              <MenuItem value="Myself">Myself</MenuItem>
              <MenuItem value="My Family">My family</MenuItem>
              <MenuItem value="Someone else">Someone else</MenuItem>
            </Select>
            <Button
              color="brand"
              variant="contained"
              onClick={handleProceedToBankDetails}
              sx={{ marginTop: 2, width: "100%" }}
              size="large"
              disabled={loading}
            >
              <Typography
                variant="h6"
                color={"black"}
                display={"flex"}
                gap={2}
                alignItems={"center"}
              >
                {loading ? (
                  <CircularProgress color="brand" size={"small"} />
                ) : null}
                Proceed
              </Typography>
            </Button>
          </Box>
        )}
      </Container>
    </CroboContainer>
  );
};

export default RecipientDetails;
