import React from "react";

export const VersionInfo = () => {
  const info = process.env.REACT_APP_VERSION;
  if (!info) {
    console.info("No version information present at build time.");
    return <></>;
  }
  console.info(`version: ${info}`);
  return <div style={{position: "fixed", bottom: "1px", right:"5px", zIndex:9999, fontSize: 12}}>{info}</div>;
};
