import React, { useEffect } from "react";
import CroboContainer from "../../components/croboContainer";
import { Avatar, Box, Container, IconButton, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { frequentContact, remitStatusUpdate } from "../../reducers/userReducer";
import Loader from "../../components/loader";
import { analytics } from "../../helper/helper"; // Import analytics
import { useNavigate } from "react-router-dom";
import FrequentContactCard from "../../components/frequentContactsCard";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import CampaignIcon from "@mui/icons-material/Campaign";
import HistoryIcon from "@mui/icons-material/History";

export const RoundedButton = (props) => {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        padding: 0,
      }}
    >
      <IconButton
        onClick={props.onClick}
        sx={{ padding: 0, width: "50px", height: "50px" }}
      >
        <Avatar
          variant="circular"
          sx={{
            height: "50px",
            width: "50px",
            bgcolor: "brand.main",
            ":hover": {
              bgcolor: "brand.dark",
            },
            fontSize: "18px",
            mb: 1,
          }}
          size="lg"
        >
          {props.icon}
        </Avatar>
      </IconButton>

     
        <Typography
          color={"black"}
          variant="body2"
          textAlign={"center"}
          onClick={props.onClick}
          sx={{
            width:"70px",
            ":hover": {
              cursor: "pointer",
            }
          }}
        >
          {props.buttonName}
        </Typography>
     
    </Box>
  );
};

function Transactions() {
  // const [phone, setPhone] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { frequentContacts, loading, bank, bankVerification, kyc, token } =
    useSelector((state) => {
      const data = state.userAuth;
      return data;
    });

  useEffect(() => {
    dispatch(remitStatusUpdate({ token }));

    if (bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5)) {
      // Valid state
      dispatch(frequentContact());
      analytics.track("Frequent Contacts Loaded"); // Track frequent contacts loaded
    } else {
      navigate("/onboarding");
      analytics.track(
        "Redirected to Onboarding due to Invalid Bank/Verification/KYC"
      ); // Track redirection to onboarding
    }
  }, [dispatch, bank, bankVerification, kyc, navigate, token]);

  return (
    <CroboContainer>
      <Container>
        <Box mt={2} sx={{ display: "flex", justifyContent: "space-around" }}>
          <RoundedButton
            icon={<AddIcon sx={{ fontSize: 30 }} />}
            buttonName="Add New Recipient"
            onClick={() => {
              navigate(`/transaction/create/new`);
            }}
          />
          <RoundedButton
            icon={<EditIcon sx={{ fontSize: 30 }} />}
            buttonName="Manage Recipient"
            onClick={() => {
              navigate("/manage-recipients");
            }}
          />
          <RoundedButton
            icon={<CampaignIcon sx={{ fontSize: 30 }} />}
            buttonName={`Refer and Earn`}
            onClick={() => {
              navigate("/referrals");
            }}
          />
          <RoundedButton
            icon={<HistoryIcon sx={{ fontSize: 30 }} />}
            buttonName="Transaction History"
            onClick={() => {
              navigate("/transaction/history");
            }}
          />
        </Box>
        {frequentContacts.length > 0 && (
          <Box sx={{ paddingTop: 0 }}>
            <Typography sx={{ paddingBottom: 0, mt: 2 }} variant="h5">
              Existing recipients
            </Typography>
            {loading ? (
              <Loader />
            ) : (
              <Box
                display={"flex"}
                overflow={"scroll"}
                flexDirection={"column"}
                sx={{
                  py: "10px",
                  pb: "20px",
                  overflowY: "auto",
                }}
              >
                {frequentContacts.map((f) => {
                  return (
                    <Box key={f.user_id} display={"flex"} flexDirection={"row"}>
                      <FrequentContactCard
                        id={f.id}
                        phone={f.phone}
                        nickName={f.unverified_user_name || f.user_name}
                        name={f.user_name}
                        email={f.email}
                      />
                    </Box>
                  );
                })}
              </Box>
            )}
          </Box>
        )}
      </Container>
    </CroboContainer>
  );
}

export default Transactions;
