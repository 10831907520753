import { Box, Button, Container, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

const RecipientsCard = ({
    id,
    name,
    phone,
    bank_account_number,
    bank_name,
    ifsc,
    email,
    pan,
    onClickEdit,
    is_bank_added,
}) => {
    const formatPhone = (phone) => {
        if (phone.length === 10) {
            return phone;
        } else if (phone.length > 10) {
            return phone.slice(-10);
        }
        return phone;
    };

    return (
      <Box
        sx={{
          p: 2,
          boxShadow: 1,
          borderRadius: 2,
          my: 2,
          bgcolor: "white",
          cursor: "default",
          "&:hover": {
            boxShadow: 5,
          },
        }}
      >
        <Box>
          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
              alignItems: "center",
            }}
          >
            <Typography variant="body1" lineHeight={1.2}>
              Name:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
              {name}
            </Typography>
          </Container>

          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
              alignItems: "center",
            }}
          >
            <Typography variant="body1" lineHeight={1.2}>
              Phone:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
              {formatPhone(phone)}
            </Typography>
          </Container>

          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
              alignItems: "center",
            }}
          >
            <Typography variant="body1" lineHeight={1.2}>
              Email:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1.2}>
              {email}
            </Typography>
          </Container>

          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
              alignItems: "center",
            }}
          >
            <Typography variant="body1" lineHeight={1}>
              Bank Account Number:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1}>
              {bank_account_number}
            </Typography>
          </Container>

          <Container
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingLeft: 0,
              alignItems: "center",
            }}
          >
            <Typography variant="body1" lineHeight={1}>
              IFSC Code:
            </Typography>
            <Typography color="text.secondary" variant="body1" lineHeight={1}>
              {ifsc}
            </Typography>
          </Container>

          <Container
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingLeft: 0,
              alignItems: "center",
            }}
          >
            <Link
              to={`/transaction/create/${phone}`}
              style={{
                ...(is_bank_added !== 1 && { pointerEvents: "none" }),
              }}
            >
              <Button
                color="brand"
                variant="contained"
                fullWidth
                size="large"
                sx={{ mt: 2 }}
                disabled={is_bank_added !== 1}
              >
                <Typography textAlign={"center"} color={"#FFF"}>
                  Start Transfer
                </Typography>
              </Button>
            </Link>
          </Container>
        </Box>
        <ToastContainer hideProgressBar position="top-center" />
      </Box>
    );
};

export default RecipientsCard;
