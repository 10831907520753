import React, { useState } from "react";
import { Container, Button, Box, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { pinSet } from "../../reducers/userReducer";
import Loader from "../../components/loader";
import { useNavigate } from "react-router-dom";
import CroboContainer from "../../components/croboContainer";
import OTPInput from "react-otp-input";

const EnterPin = ({ pin, setPin, setPinCheck, error }) => {
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Typography textAlign={"center"} variant={"h5"}>
          SET PIN
        </Typography>
      </Box>

      <Box>
        <OTPInput
          value={pin}
          label="PIN"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setPin}
          numInputs={4}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                borderRadius: "8px",
                border: `1px solid ${error ? "#ff3333" : "#E0E0E0"}`,
                height: "40px",
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
      </Box>

      {error && (
        <Typography color="error" textAlign="center" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      <Box paddingY={2}>
        <Button
          color="brand"
          onClick={() => {
            if (pin.length === 4) {
              setPinCheck(true);
            }
          }}
          variant="contained"
          fullWidth
          size="large"
          disabled={pin.length !== 4}
          sx={{
            py: 1.5,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '1rem'
          }}
        >
          <Typography>Proceed</Typography>
        </Button>
      </Box>
    </Container>
  );
};

const ConfirmPin = ({ confirmPin, setConfirmPin, resetPin, loading, error }) => {
  return (
    <Container>
      <Box paddingTop={10} paddingBottom={4}>
        <Typography textAlign={"center"} variant={"h5"}>
          Confirm PIN
        </Typography>
      </Box>

      <Box>
        <OTPInput
          value={confirmPin}
          label="PIN"
          inputType="number"
          shouldAutoFocus="true"
          onChange={setConfirmPin}
          numInputs={4}
          renderSeparator={<span style={{ margin: 5 }}></span>}
          renderInput={(props) => (
            <input
              {...props}
              style={{
                width: "100%",
                height: "40px",
                borderRadius: "8px",
                border: `1px solid ${error ? "#ff3333" : "#E0E0E0"}`,
                fontSize: 18,
                textAlign: "center",
                fontFamily: "Arial",
              }}
            />
          )}
        />
      </Box>

      {error && (
        <Typography color="error" textAlign="center" sx={{ mt: 1 }}>
          {error}
        </Typography>
      )}

      <Box paddingY={2}>
        {loading ? (
          <Loader />
        ) : (
          <Button
            color="brand"
            onClick={resetPin}
            variant="contained"
            fullWidth
            size="large"
            disabled={confirmPin.length !== 4}
            sx={{
              py: 1.5,
              borderRadius: 2,
              textTransform: 'none',
              fontSize: '1rem'
            }}
          >
            <Typography>Confirm</Typography>
          </Button>
        )}
      </Box>
    </Container>
  );
};

const SetPin = () => {
  const [pin, setPin] = useState("");
  const [confirmPin, setConfirmPin] = useState("");
  const [pinCheck, setPinCheck] = useState(false);
  const [error, setError] = useState("");
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.userAuth);
  const navigate = useNavigate();

  const resetPin = () => {
    if (pin.length !== 4) {
      setError("Please enter a 4-digit PIN");
      return;
    }
    
    if (confirmPin.length !== 4) {
      setError("Please confirm your PIN");
      return;
    }

    if (pin === confirmPin) {
      setError("");
      dispatch(pinSet(pin));
      navigate('/pin');
    } else {
      setError("PINs do not match");
      setPinCheck(false);
      setConfirmPin("");
    }
  };

  return (
    <CroboContainer>
      {!pinCheck ? (
        <EnterPin 
          pin={pin} 
          setPin={setPin} 
          setPinCheck={setPinCheck}
          error={error}
        />
      ) : (
        <ConfirmPin
          confirmPin={confirmPin}
          setConfirmPin={setConfirmPin}
          resetPin={resetPin}
          loading={loading}
          error={error}
        />
      )}
    </CroboContainer>
  );
};

export default SetPin;
