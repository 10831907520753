import React, { useRef } from "react";
import { Paper, Typography, IconButton, Box, Avatar, Divider } from "@mui/material";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FormatQuoteIcon from '@mui/icons-material/FormatQuote';

const testimonials = [
  {
    name: "Yashwanth",
    testimonial:
      "Crobo has a user-friendly interface, robust security measures, and real-time exchange rate updates. Very responsive customer support. The app's efficiency and security make it a promising choice for hassle-free financial transactions.",
  },
  {
    name: "Satvik",
    testimonial:
      "I just tried to send money using Crobo! It was instant! This is amazing! Love this service! Will definitely spread the word! Keep up the great work!",
  },
  {
    name: "Karthik",
    testimonial: "Will recommend the app to my friends as well, all the best!",
  },
  {
    name: "Keerthika",
    testimonial:
      "I just sent money using this app. The onboarding process was seamless and was done in minutes. Transfer was super quick and they give you a better rate than google. Love it!!! Would definitely recommend it.",
  },
  {
    name: "Madhu",
    testimonial:
      "I'm really impressed by the transfer speeds. It takes more time for a direct deposit to different accounts in the US. This is equivalent to Zelle/UPI for an international transfer.",
  },
  {
    name: "Sanjay",
    testimonial:
      "The process was incredibly user-friendly, making it easy for me to send money to my family in India. What really stood out was the speed of the transaction - it was almost instantaneous.",
  },
];

const TestimonialsCarousel = () => {
  const scrollContainerRef = useRef(null);
  const carouselWidth = 350;

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      if (scrollContainerRef.current.scrollLeft === 0) {
        // Scroll to the end if at the start
        scrollContainerRef.current.scrollTo({
          left: scrollContainerRef.current.scrollWidth,
          behavior: "smooth",
        });
      } else {
        scrollContainerRef.current.scrollBy({
          left: -carouselWidth,
          behavior: "smooth",
        });
      }
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      if (
        scrollContainerRef.current.scrollLeft +
          scrollContainerRef.current.clientWidth >=
        scrollContainerRef.current.scrollWidth
      ) {
        // Scroll to the start if at the end
        scrollContainerRef.current.scrollTo({
          left: 0,
          behavior: "smooth",
        });
      } else {
        scrollContainerRef.current.scrollBy({
          left: carouselWidth,
          behavior: "smooth",
        });
      }
    }
  };

  return (
    <Box sx={{ position: "relative", py: 4 }}>
      {/* Navigation Buttons */}
      <IconButton
        onClick={scrollLeft}
        sx={{
          position: "absolute",
          left: { xs: -8, md: -20 },
          top: "50%",
          transform: "translateY(-50%)",
          bgcolor: "background.paper",
          boxShadow: 2,
          zIndex: 2,
          "&:hover": {
            bgcolor: "background.paper",
          },
          display: { xs: "none", md: "flex" },
        }}
      >
        <ArrowBackIosNewIcon fontSize="small" />
      </IconButton>

      <IconButton
        onClick={scrollRight}
        sx={{
          position: "absolute",
          right: { xs: -8, md: -20 },
          top: "50%",
          transform: "translateY(-50%)",
          bgcolor: "background.paper",
          boxShadow: 2,
          zIndex: 2,
          "&:hover": {
            bgcolor: "background.paper",
          },
          display: { xs: "none", md: "flex" },
        }}
      >
        <ArrowForwardIosIcon fontSize="small" />
      </IconButton>

      {/* Testimonials Container */}
      <Box
        ref={scrollContainerRef}
        sx={{
          display: "flex",
          overflow: "auto",
          py: 2,
          px: { xs: 2, md: 0 },
          scrollSnapType: "x mandatory",
          "&::-webkit-scrollbar": { display: "none" },
          scrollbarWidth: "none",
          gap: 3,
        }}
      >
        {testimonials.map((testimonial, index) => (
          <Paper
            key={testimonial.name}
            elevation={0}
            sx={{
              minWidth: { xs: "85vw", sm: `${carouselWidth}px` },
              scrollSnapAlign: "center",
              borderRadius: 3,
              overflow: "hidden",
              border: "1px solid",
              borderColor: "grey.200",
              bgcolor: "brandDark.main",

              transition: "all 0.3s ease",
              "&:hover": {
                transform: "translateY(-4px)",
                boxShadow: 3,
              },
            }}
          >
            {/* Testimonial Content */}
            <Box sx={{ p: 3 }}>
              {/* Quote Icon */}
              {/* Author */}
              <Box sx={{ textAlign: "center" }}>
                <Avatar
                  sx={{
                    width: 56,
                    height: 56,
                    bgcolor: "brandLight.main",
                    color: "brand.main",
                    fontSize: 28,
                    margin: "0 auto",
                    mb: 1,
                  }}
                >
                  {testimonial.name[0]}
                </Avatar>
                <Typography
                  variant="subtitle1"
                  fontWeight={600}
                  color="brand.main"
                >
                  {testimonial.name}
                </Typography>
                <Typography variant="caption" color="white">
                  Verified Customer
                </Typography>

                {/* Divider */}
                <Divider sx={{ my: 2, bgcolor:"brandLight.main" }} />
              </Box>
              {/* Testimonial Text */}
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  mb: 3,
                  color: "white",
                  lineHeight: 1.8,
                  fontStyle: "italic",
                }}
              >
                "{testimonial.testimonial}"
              </Typography>

              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mb: 2,
                }}
              >
                <FormatQuoteIcon
                  sx={{
                    fontSize: 40,
                    color: "white",
                    opacity: 0.7,
                  }}
                />
              </Box>
            </Box>
          </Paper>
        ))}
      </Box>

      {/* Mobile Navigation Dots */}
      <Box
        sx={{
          display: { xs: "flex", md: "none" },
          justifyContent: "center",
          gap: 1,
          mt: 3,
        }}
      >
        {testimonials.map((_, index) => (
          <Box
            key={index}
            sx={{
              width: 8,
              height: 8,
              borderRadius: "50%",
              bgcolor: "brand.main",
              opacity: 0.3,
              "&.active": {
                opacity: 1,
              },
            }}
          />
        ))}
      </Box>
    </Box>
  );
};

export default TestimonialsCarousel;
