// Auth.js
import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";

import "../App.css";
import Nav from "./nav";
import { useLocation } from "react-router-dom";
import { analytics } from "../helper/helper";
// import BottomNavigation from "./bottomNavigation";

const CroboContainer = ({ children }) => {
  const location = useLocation();

  useEffect(() => {
    const pageName =
      location.pathname.replace(/\//g, "").toUpperCase() || "HOME";

    analytics.screen(`REMIT ${pageName}`, {
      page_name: pageName,
      path: location.pathname,
      search: location.search,
      hash: location.hash,
    });
  }, [location]);

  return (
    <Box sx={{ height: "100dvh" }}>
      <Box>
        <Nav />
      </Box>
      <Grid
        container
        direction="row"
        justifyContent="center"
        pt={"55px"}
        sx={{ height: "100%" }}
      >
        <Grid
          xs={12}
          sm={8}
          md={5}
          xl={3}
          item={true}
          height={"100%"}
          alignItems={"center"}
          display={"flex"}
          justifyContent={"center"}
          flexDirection={"column"}
        >
          <Box flexGrow={1} sx={{width:'100%', height:'100%', overflow:'scroll'}}>{children}</Box>
          <Box flexGrow={0} sx={{zIndex:100000}}>
            {/* <BottomNavigationBar /> */}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default CroboContainer;
