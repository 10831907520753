import React, { useState, useEffect } from "react";
import {
  Button,
  Box,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import DocumentUploadInstructions from "./DocumentUploadInstructions";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  apiRoutes,
  remitStatusUpdate,
  setLoader,
} from "../../reducers/userReducer";
import {
  cybridRemitUrl,
  kycTypes,
  officialWebsiteUrl,
  sdkRedirectToRoute,
} from "../../constants/apiConstants";
import { runPersonaClient } from "../../components/persona";
import axios from "axios";
import { usePlaid } from "../SDK/plaid";
import { pollRemitStatus } from "../../helper/poll";

const ActionButtons = ({ state }) => {
  const dispatch = useDispatch();
  const [isPolling, setIsPolling] = useState(false);
  const [elapsedTime, setElapsedTime] = useState(60);
  const [internalLoader, setInternalLoader] = useState(false);

  const finalPolling = async (isRefreshRequired = false) => {
    dispatch(setLoader(true));
    setIsPolling(!isPolling);
    setElapsedTime(60);
    clearInterval(updateTimer && updateTimer);
    updateTimer = setInterval(() => {
      setElapsedTime((prevTime) => prevTime - 1);
    }, 1000);
    // await startPolling(dispatch, remitStatusUpdate, token, 2, setLoader, 2000);
    pollRemitStatus({
      setLoader: setInternalLoader,
      dispatch,
      remitStatusUpdate,
      isRefreshRequired
    });
  };
  const { setBankBypass, bankBypass } = state;
  const { token, customMessage } = useSelector((state) => state.userAuth);
  const { open, ready } = usePlaid({
    setBankBypass: setBankBypass,
    bankBypass,
    finalPolling: finalPolling,
    setInternalLoader: setInternalLoader,
    setIsPolling: setIsPolling,
  });

  const navigate = useNavigate();
  let updateTimer;
  useEffect(() => {
    if (elapsedTime === 0) {
      setIsPolling(false);
      clearInterval(updateTimer && updateTimer);
      setElapsedTime(60);
    }
  }, [elapsedTime, updateTimer]);

  const connectPlaid = (guid, bank, kyc) => {
    const handleKycCheck = () => {
      dispatch(setLoader(true));
      dispatch(remitStatusUpdate({ token, isRefresh: true }));
      getKycPersonaId(kycTypes.id_and_selfie);
    };

    if (state.bankBypass) {
      handleKycCheck();
      return;
    }

    if (!bank) {
      setInternalLoader(true);
      return open();
    }

    if (!kyc) {
      handleKycCheck();
    } else {
      const url = `${cybridURL(guid, bank, kyc, state.token)}`;
      window.open(url, "_blank");
    }
  };
  const cybridURL = (guid, bank, kyc, token) => {
    const environment = {
      cybridRemitUrl: cybridRemitUrl,
      officialWebsiteUrl: officialWebsiteUrl,
    };
    const redirectToRoute = sdkRedirectToRoute;
    return `${environment.cybridRemitUrl}?customer_guid=${guid}&bank=${bank}&kyc=${kyc}&token=${token}&redirect=${environment.officialWebsiteUrl}${redirectToRoute}`;
  };

  const performRedirectionAction = (guid, bank, kyc) => {
    const actionTable = {
      "00": () => {
        connectPlaid(guid, false, false);
        changeStatus(false, true, false);
      },
      "01": () => {
        connectPlaid(guid, false, true);
        changeStatus(false, true, false);
      },
      "02": () => {
        connectPlaid(guid, false, false);
        changeStatus(false, true, false);
      },
      "03": () => {
        connectPlaid(guid, false, false);
        changeStatus(false, true, false);
      },
      "05": () => {
        connectPlaid(guid, false, true);
        changeStatus(false, true, false);
      },
      10: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      11: () => {
        if (state.bankVerification === 1) {
          redirectTo("transaction"); // Adjust as necessary
        } else if (
          state.bankVerification === 0 ||
          state.bankVerification === 2
        ) {
          // redirectTo('onboarding'); // Adjust as necessary
        } else if (state.bankVerification === 3) {
          connectPlaid(guid, false, true);
        }
        changeStatus(false, true, false);
      },
      12: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      13: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      20: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      21: () => {
        if (state.bankVerification === 1) {
          // redirectTo('onboarding'); // Adjust as necessary
        } else if (
          state.bankVerification === 0 ||
          state.bankVerification === 2
        ) {
          // redirectTo('onboarding'); // Adjust as necessary
        } else if (state.bankVerification === 3) {
          connectPlaid(guid, false, true);
        }
        changeStatus(false, true, false);
      },
      22: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      23: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      30: () => {
        connectPlaid(guid, false, false);
        changeStatus(false, true, false);
      },
      31: () => {
        connectPlaid(guid, false, true);
        changeStatus(false, true, false);
      },
      32: () => {
        connectPlaid(guid, false, false);
        changeStatus(false, true, false);
      },
      33: () => {
        connectPlaid(guid, false, false);
        changeStatus(false, true, false);
      },
      35: () => {
        connectPlaid(guid, false, true);
        changeStatus(false, true, false);
      },
      40: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      41: () => {
        connectPlaid(guid, true, true);
        changeStatus(false, true, false);
      },
      42: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      43: () => {
        connectPlaid(guid, true, false);
        changeStatus(false, true, false);
      },
      45: () => {
        connectPlaid(guid, true, true);
        changeStatus(false, true, false);
      },
      "04": () => {
        connectPlaid(guid, false, true);
      },
      14: () => {
        connectPlaid(guid, true, true);
      },
      24: () => {
        connectPlaid(guid, true, true);
      },
      34: () => {
        connectPlaid(guid, false, true);
      },
      44: () => {
        connectPlaid(guid, true, true);
      },
    };

    const actionKey = `${bank}${kyc}`;
    const action = actionTable[actionKey];
    if (action) {
      action();
    } else {
      console.warn(`No action found for bank=${bank} and kyc=${kyc}`);
    }
  };

  const redirectTo = (route) => {
    navigate(`/${route}`);
  };

  const changeStatus = (sdkStatus, kycStatus, bankStatus) => {
    console.log(
      `Changing status: sdkStatus=${sdkStatus}, kycStatus=${kycStatus}, bankStatus=${bankStatus}`
    );
  };

  const handleProceedClick = () => {
    performRedirectionAction(state.guid, state.bank, state.kyc);
  };

  const onPersonaCancel = async () => {
    // dispatch(setRemitLoaded(false));
    dispatch(setLoader(true));
    // setIsPolling(true);
    await new Promise((resolve) => setTimeout(resolve, 1000)); // 2 seconds delay
    // const updateTimer = setInterval(() => {
    //   setElapsedTime(prevTime => prevTime - 1);
    // }, 1000);
    // setIsPolling(await startPolling(dispatch, remitStatusUpdate, token, 2, setLoader));
    // clearInterval(updateTimer);
    await dispatch(remitStatusUpdate({ token, isRefresh: "false" }));
    // setIsPolling(false);
  };

  const handleUploadClick = () => {
    // dispatch(setRemitLoaded(false));
    dispatch(setLoader(true));
    getKycPersonaId(kycTypes.document_submission);
  };

  const getKycPersonaId = async (type) => {
    const token = localStorage.getItem("token");
    try {
      let config = {
        method: "post",
        url: apiRoutes.remitIdentityVerification,
        headers: { "x-auth-token": token },
        data: {
          type: type,
        },
      };

      const payload = await axios.request(config);
      // dispatch(setRemitLoaded(true));
      dispatch(setLoader(false));
      await runPersonaClient(
        payload.data.inquiry_id,
        finalPolling,
        onPersonaCancel
      );
    } catch (error) {
      console.error("Error getting persona id:", error);
    }
  };

  return (
    <Container>
      {customMessage && (
        <Typography fontSize={14} margin={2} textAlign={"center"}>
          {customMessage}
        </Typography>
      )}
      {(state.bankVerification === 3 || state.bankVerification === 2) &&
      state.showDocumentsButton &&
      !isPolling ? (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column">
          <Button
            variant="contained"
            color="brand"
            size="large"
            onClick={handleUploadClick}
          >
            Upload documents
          </Button>
          <Box mt={2}>
            <DocumentUploadInstructions />
          </Box>
        </Box>
      ) : (
        state.bankVerification !== 3 &&
        state.bankVerification !== 2 && (
          <Box
            display={"flex"}
            justifyContent={"center"}
            flexDirection="column"
          >
            <Button
              variant="contained"
              onClick={
                ready && !state.loading && !isPolling
                  ? handleProceedClick
                  : () => {}
              }
              color="brand"
              size="large"
              disabled={!ready || state.loading || internalLoader || isPolling || state.kyc == 4} // Disable if Plaid is not ready
            >
              {ready && !state.loading && !internalLoader && !isPolling ? (
                "Proceed"
              ) : (
                <>
                  <CircularProgress size={24} color="inherit" />
                  {isPolling ? (
                    <p>
                      {" "}
                      Waiting Time: {Math.floor(elapsedTime / 60)}:
                      {elapsedTime % 60 < 10
                        ? `0${elapsedTime % 60}`
                        : elapsedTime % 60}
                    </p>
                  ) : (
                    <></>
                  )}
                </>
              )}{" "}
            </Button>
          </Box>
        )
      )}
    </Container>
  );
};

export default ActionButtons;
