import {
    Container,
    Button,
    Box,
    Grid,
    Typography,
    IconButton,
} from "@mui/material";
import referImage from "../../assets/refer_and_earn.png";
import CroboContainer from "../../components/croboContainer";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { getProfile, redeem } from "../../reducers/userReducer";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import WhatsappContact from "../../components/whatsappTab";
import { useNavigate } from 'react-router-dom';
import { RWebShare } from "react-web-share";
import BottomBranding from "../../components/bottomBranding";

const shareMessage = (referral_code, amount = 10) => {
    return `Crobo ensures instant, no-fee transfers from the US to India. Sign up with Crobo: https://crobo.money/send-signup via my referral link and earn $${amount} instantly by using my Referral Code during signup: ${referral_code}`
}

const Referrals = () => {
    const userStore = useSelector((state) => {
        return state.userAuth?.profile
    });
    const envStore = useSelector((state) => {
        return state.resource?.envList;
    });
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getProfile());
    }, [dispatch]);
    const notify = () => toast.success("Referral code copied!");
    const notifyRedeemSuccess = () => toast.success("Redeem successfully generated, please check your email!");
    const navigate = useNavigate();
    const [isRedeemAvailable, setRedeemAvailable] = useState(userStore?.balance?.REF <= 0);
    return (
        <CroboContainer>
            <Container maxWidth="sm" sx={{ px: 2 }}>
                <Grid 
                    container 
                    direction="column" 
                    spacing={2}
                    sx={{ py: 3 }}
                >
                    {/* Header Image */}
                    <Grid item xs={12} sx={{ textAlign: 'center' }}>
                        <img 
                            src={referImage} 
                            style={{
                                width: '100%',
                                maxWidth: '300px',
                                height: 'auto'
                            }} 
                            alt="Refer and Earn" 
                        />
                        <Typography variant="h6" sx={{ mt: 2 }}>
                            Share your referral code to earn
                        </Typography>
                    </Grid>

                    {/* Rewards Section */}
                    <Grid item xs={12}>
                        <Box sx={{
                            p: 2,
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 1
                        }}>
                            <Typography variant="subtitle1" fontWeight="medium">
                                Your Rewards
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1
                            }}>
                                <Typography variant="h5" fontWeight="bold">
                                    ${parseFloat(userStore?.balance?.REF || 0).toFixed(2)}
                                </Typography>
                                <Button
                                    variant='contained'
                                    disabled={isRedeemAvailable}
                                    onClick={() => {
                                        setRedeemAvailable(true)
                                        dispatch(redeem({
                                            data: { amount: userStore?.balance?.REF },
                                            action: notifyRedeemSuccess
                                        }))
                                    }}
                                    sx={{
                                        borderRadius: 3,
                                        bgcolor: 'brand.main',
                                        "&:hover": { bgcolor: "brand.dark" },
                                    }}
                                >
                                    Redeem
                                </Button>
                            </Box>
                        </Box>
                        <Button 
                            onClick={() => navigate('/referrals/redeem-history')}
                            sx={{ mt: 1, textDecoration: 'underline' }}
                        >
                            View Redeem History
                        </Button>
                    </Grid>

                    {/* Referral Code Section */}
                    <Grid item xs={12}>
                        <Box sx={{
                            p: 2,
                            bgcolor: 'background.paper',
                            borderRadius: 2,
                            boxShadow: 1
                        }}>
                            <Typography variant="subtitle1" fontWeight="medium">
                                Referral Code
                            </Typography>
                            <Box sx={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                mt: 1,
                                p: 2,
                                bgcolor: 'grey.100',
                                borderRadius: 2
                            }}>
                                <Typography variant="h6" fontFamily="monospace">
                                    {userStore?.referral_code}
                                </Typography>
                                <IconButton 
                                    onClick={() => {
                                        navigator.clipboard.writeText(userStore?.referral_code)
                                        notify()
                                    }}
                                    sx={{ color: 'brand.main' }}
                                >
                                    <ContentCopyIcon />
                                </IconButton>
                            </Box>
                        </Box>
                        <Button 
                            onClick={() => navigate('/referrals/referral-history')}
                            sx={{ mt: 1, textDecoration: 'underline' }}
                        >
                            View Referral History
                        </Button>
                    </Grid>

                    {/* Info Section */}
                    <Grid item xs={12}>
                        <Typography variant="body1" textAlign="center" sx={{ mt: 2 }}>
                            After the first transaction, both you and your referee receive a ${envStore?.REFERRER_AMOUNT || 25} Amazon Gift Card
                        </Typography>
                        <Button 
                            fullWidth
                            onClick={() => navigate('/referrals/refer-faq', {
                                state: {
                                    referral_amount: envStore?.REFERRER_AMOUNT || 25,
                                    threshold_amount: envStore?.THRESHOLD_REFERRAL_AMOUNT || 500
                                }
                            })}
                            sx={{ mt: 1, textDecoration: 'underline', color: 'brand.main' }}
                        >
                            Know More
                        </Button>
                    </Grid>

                    {/* Share Buttons */}
                    <Grid item xs={12}>
                        <Box sx={{
                            display: 'flex',
                            gap: 2,
                            justifyContent: 'center',
                            mt: 2
                        }}>
                            <WhatsappContact 
                                message={shareMessage(userStore?.referral_code, envStore?.REFERRER_AMOUNT)} 
                                number={'1023'}
                            >
                                
                                    Share on WhatsApp
                                
                            </WhatsappContact>
                            
                            <RWebShare
                                data={{
                                    text: shareMessage(userStore?.referral_code, envStore?.REFERRER_AMOUNT),
                                    url: userStore?.referral_code,
                                    title: "Referral Code",
                                }}
                                disableNative
                                sites={['facebook', 'linkedin', 'telegram', 'reddit', 'mail', 'twitter']}
                            >
                                <Button 
                                    variant="contained" 
                                    sx={{
                                        borderRadius: 3,
                                        bgcolor: 'brand.main',
                                        "&:hover": { bgcolor: "brand.dark" },
                                    }}
                                >
                                    Share More
                                </Button>   
                            </RWebShare>
                        </Box>
                    </Grid>
                </Grid>
                <BottomBranding/>
            </Container>
            <ToastContainer
                hideProgressBar
                position="top-center"
            />
        </CroboContainer>
    );
}

export default Referrals;