import React from "react";
import { Container, Typography, Box, Divider, List, ListItem, ListItemText } from "@mui/material";

const ReferralProgramTerms = () => {
    return (
      <Container>
        <Box my={4}>
          <Typography variant="h4" gutterBottom>
            New User Signup Reward Program Terms and Conditions
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            Effective Date: November 22, 12:00 AM PST
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            End Date: November 30, 11:59 PM PST
          </Typography>
          <Divider />
          <Box my={2}>
            <Typography variant="h6" gutterBottom>
              Welcome to our New User Signup Reward Program! By participating,
              you agree to the following terms and conditions:
            </Typography>
            <List>
              <ListItem>
                <ListItemText
                  primary="Eligibility"
                  secondary="Open to new users who register on our platform during the program duration. The new user must complete a minimum transaction on our platform to qualify for the reward."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Signup Reward"
                  secondary="New users who sign up using the promo code BLACKFRIDAY will receive a $25 bonus. The reward will be issued after the user completes a minimum transaction of $500 on our platform."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Program Duration"
                  secondary="The program begins on November 22, 12:00 AM PST, and ends on November 30, 12:00 AM PST. Transactions must be completed within this timeframe to qualify for the reward."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Reward Conditions"
                  secondary="The new user must complete a transaction of at least $500 to be eligible for the $25 signup reward. The reward will only be issued if the minimum transaction requirement is met within the program duration."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Reward Issuance"
                  secondary="The $25 bonus will be credited to the user’s account within 7 days after completing the qualifying transaction."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Fraudulent Activity"
                  secondary="Any fraudulent or abusive behavior, gaming of the system, or violation of these terms will result in disqualification from the program and forfeiture of any rewards."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Modification and Termination"
                  secondary="We reserve the right to modify or terminate the signup reward program at any time without prior notice. Changes will be posted on our website."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="General Conditions"
                  secondary="Participation in the signup reward program is voluntary. Rewards are non-transferable, non-exchangeable, and non-refundable. We are not responsible for any lost or misallocated bonuses. In case of any dispute, our decision will be final and binding. The program is subject to all applicable federal, state, and local laws and regulations."
                />
              </ListItem>
              <Divider />
              <ListItem>
                <ListItemText
                  primary="Contact Information"
                  secondary="For questions or concerns regarding the signup reward program, please contact our support team at care@crobo.money."
                />
              </ListItem>
            </List>
            <Typography variant="body1" gutterBottom>
              By participating in this signup reward program, you acknowledge
              that you have read, understood, and agree to be bound by these
              terms and conditions. Thank you for joining our community!
            </Typography>
          </Box>
        </Box>
      </Container>
    );
};

export default ReferralProgramTerms
