import {
    Container,
    Button,
    Grid,
    Typography,
} from "@mui/material";
import referImage from "../../assets/refer_and_earn.png";
import CroboContainer from "../../components/croboContainer";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useLocation, useNavigate } from "react-router-dom";

const ReferAndEarnFaq = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    return (
        <>
            <CroboContainer>
                <Container>
                    <Grid justifyContent="center" container marginTop={1} height={40}>
                        <img src={referImage} width={'60%'} alt="img" />
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 4
                        }}>
                            <Typography variant="h6">
                                Step 1
                            </Typography>
                            <Typography  marginTop={1}>
                                Share your unique code with your friends.
                            </Typography>
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 4
                        }}>
                            <Typography variant="h6">
                                Step 2
                            </Typography>
                            <Typography  marginTop={1}>
                                They make a transfer of at least ${state.threshold_amount}, you get ${state.referral_amount}.
                            </Typography>
                        </Container>
                        <Container sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            marginTop: 4
                        }}>
                            <Typography variant="h6">
                                Step 3
                            </Typography>
                            <Typography marginTop={1}>
                                You can redeem your rewards as an Amazon Gift Card.
                            </Typography>
                        </Container>

                        <Button variant="contained" sx={{
                            marginTop: 4,
                            bgcolor: 'brand.main'
                        }} onClick={() => {
                            navigate('/referrals')
                        }}>
                            Back
                        </Button>
                    </Grid>
                </Container>
                <ToastContainer
                    hideProgressBar
                    position="top-center"
                />
            </CroboContainer>
        </>
    );
}

export default ReferAndEarnFaq;