export const pollRemitStatus = async ({
  setLoader,
  dispatch,
  remitStatusUpdate,
  isRefreshRequired
}) => {
  const maxAttempts = isRefreshRequired ? 5: 60;
  const interval = isRefreshRequired? 9000: 3000;
  let attempts = 0;
  setLoader(true);

  while (attempts < maxAttempts) {
    try {
      const token = localStorage.getItem("token");
      const bank = localStorage.getItem("bank");
      const kyc = localStorage.getItem("kyc");
      const bankVerification = localStorage.getItem("bankVerification");

      dispatch(remitStatusUpdate({ token, isRefresh: isRefreshRequired }));
      
      if (
        kyc === 1 && 
        bank === 1 && 
        (bankVerification === 1 || bankVerification === 2 || bankVerification === 3)
      ) {
        setLoader(false);
        return true;
      }

      await new Promise((resolve) => setTimeout(resolve, interval));
      attempts++;
    } catch (error) {
      console.log(error);
      await new Promise((resolve) => setTimeout(resolve, interval));
      attempts++;
    }
  }

  // throw new Error(`Polling timed out after ${maxAttempts} attempts`);
  setLoader(false)
}

// // Example usage:
// async function example() {
//     try {
//         const result = await pollAPI({
//             apiCall: async () => {
//                 // Your API call here
//                 const response = await fetch('https://api.example.com/status');
//                 return response.json();
//             },
//             validate: (response) => {
//                 // Your validation logic here
//                 return response.status === 'completed';
//             },
//             interval: 2000,      // Poll every 2 seconds
//             maxAttempts: 15,     // Try for up to 30 seconds
//             onError: (error, attempt) => {
//                 console.log(`Attempt ${attempt} failed:`, error);
//             }
//         });

//         console.log('Polling succeeded:', result);
//     } catch (error) {
//         console.log('Polling failed:', error);
//     }
// }

