import {
  getAnalytics,
  logEvent,
  setUserId,
  setUserProperties,
} from "firebase/analytics";
import { app } from "../service/firebase";

export function processName(sentence) {
  const words = sentence?.split(" ");
  const firstTwoWords = words?.slice(0, 2);
  const upperCaseWords = firstTwoWords?.map((word) => word.toUpperCase());
  const firstLetters = upperCaseWords?.map((word) => word.charAt(0));
  const resultLetters = firstLetters?.join("");
  const resultWords = upperCaseWords?.join(" ");

  return {
    letters: resultLetters,
    words: resultWords,
  };
}

export const amountRoundOff = (amount, precision = 2) => {
  const regex = new RegExp(
    "^\\d+(?:\\.\\d{0," + precision.toString() + "})?",
    ""
  );
  if (amount) return Number(amount.toString().match(regex));
  else return 0;
};

export const handleError = async (error) => {
  const response = {
    message: "",
    code: 0,
  };
  if (error.response) {
    response.message = error.response.data.message;
  } else if (error.request) {
    response.message = "No response received from server";
  } else {
    response.message = error.message;
  }
  response.code = error.response.data.response_code;
  analytics.track(`ERROR ${response.message}`, {
    ...error,
  });
  return response;
};

export function matchIsNumeric(text) {
  const isNumber = typeof text === "number";
  const isString = typeof text === "string";
  return (isNumber || (isString && text !== "")) && !isNaN(Number(text));
}

export const handleOpenChat = () => {
  const profile = localStorage.getItem("profile")
    ? JSON.parse(localStorage.getItem("profile"))
    : null;

  if (profile) {
    window.fcWidget.setExternalId(profile.user_id);
    window.fcWidget.user.setFirstName(profile.unverified_user_name);
    window.fcWidget.user.setEmail(profile.unverified_email);
    window.fcWidget.user.setProperties({
      phone: localStorage.getItem("phone")
        ? localStorage.getItem("phone")
        : null,
    });
  }
  window.fcWidget.open();
};
export const errorAnalytics = (message, err = null) => {
  const phone = localStorage.getItem("phone");
  analytics.track(`ERROR ${message}`, {
    ...err,
    phone: phone ? phone : null,
  });
};

export function convertToHourMinute(isoString) {
  const date = new Date(isoString);
  const hours = date.getUTCHours().toString().padStart(2, "0");
  const minutes = date.getUTCMinutes().toString().padStart(2, "0");
  return `${hours}:${minutes}`;
}

export function convertToDate(isoString) {
  const d = new Date(isoString);
  const year = d.getUTCFullYear();
  const month = d.getUTCMonth();
  const day = d.getUTCDate();
  return `${day}/${month + 1}/${year}`;
}

export function getUTCTime(localTimeString) {
  // Create a Date object using the provided local time string
  const date = new Date(localTimeString);

  // Extract the UTC components from the Date object
  const utcYear = date.getUTCFullYear();
  const utcMonth = String(date.getUTCMonth() + 1).padStart(2, "0"); // Months are 0-indexed
  const utcDay = String(date.getUTCDate()).padStart(2, "0");
  const utcHours = String(date.getUTCHours()).padStart(2, "0");
  const utcMinutes = String(date.getUTCMinutes()).padStart(2, "0");
  const utcSeconds = String(date.getUTCSeconds()).padStart(2, "0");

  // Return the UTC time in the format 'YYYY-MM-DD HH:MM:SS'
  return `${utcYear}-${utcMonth}-${utcDay} ${utcHours}:${utcMinutes}:${utcSeconds}`;
}
const firebaseAnalytics = getAnalytics(app);

/**
 * Analytics helper object to track events.
 */
export const analytics = {
  /**
   * Tracks an event with the given properties.
   * Sends the event to both Segment and Firebase analytics.
   *
   * @param {string} event - The name of the event to track.
   * @param {Object} properties - The properties of the event.
   */
  track: (event, properties) => {
    window.analytics.track(event, properties);
    logEvent(firebaseAnalytics, event, properties);
  },
  screen: (name, properties) => {
    window.analytics.screen(name, properties);
    logEvent(firebaseAnalytics, "screen_view", {
      screen_name: name,
      ...properties,
    });
  },
  page: (name, properties) => {
    window.analytics.page(name, properties);
    logEvent(firebaseAnalytics, "page_view", {
      page_title: name,
      ...properties,
    });
  },
  identify: (userId, traits) => {
    window.analytics.identify(userId, traits);
    setUserId(firebaseAnalytics, userId);
    setUserProperties(firebaseAnalytics, traits);
  },
};
