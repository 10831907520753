import React, { useEffect, useState } from "react";
import CroboContainer from "../../components/croboContainer";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  Container,
  Grid,
  Modal,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { fetchExchangeRate } from "../../reducers/exchangeRateReducer";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../components/loader";
import { fetchUsers, remitStatusUpdateCall } from "../../reducers/userReducer";
import { amountRoundOff, analytics } from "../../helper/helper"; // Import analytics
import {
  resetTransaction,
  sendPayment,
} from "../../reducers/transactionReducer";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import PersonIcon from "@mui/icons-material/Person";
import AccountBalanceWalletIcon from "@mui/icons-material/AccountBalanceWallet";
import EastIcon from "@mui/icons-material/East";
import BottomBranding from "../../components/bottomBranding";

const isValidKycState = (bank, bankVerification, kyc) => {
  return bank === 1 && bankVerification === 1 && (kyc === 1 || kyc === 5);
};

const Confirm = () => {

  const { id, amount } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exchangeRate, loading, instantExchangeRate } = useSelector(
    (state) => state.exchangeRate
  );
  const transaction = useSelector((state) => state.transaction);
  const [open, setOpen] = useState(false);
  const user = useSelector((state) => state.userAuth);
  const { bank, bankVerification, kyc } = user;
  const { fetchUser, bankDetails, token } = user;
  const location = useLocation();
  const { purpose, is_instant } = location.state || {}; // Get the passed state

  useEffect(() => {
    dispatch(resetTransaction());
    analytics.track("Transaction Reset"); // Track transaction reset
  }, [dispatch]);

  useEffect(() => {
    if (!isValidKycState(bank, bankVerification, kyc)) {
      // Invalid state
      const checkStatus = async () => {
        const remitStatusUpdateResponse = await remitStatusUpdateCall(token);
        if (
          !isValidKycState(
            remitStatusUpdateResponse.bank,
            remitStatusUpdateResponse.bank_verification,
            remitStatusUpdateResponse.kyc
          )
        ) {
          navigate("/onboarding");
          analytics.track(
            "Redirected to Onboarding due to Invalid Bank/Verification/KYC"
          ); // Track redirection to onboarding
        }
      };
      checkStatus();
    }
  }, [dispatch, bank, bankVerification, kyc, navigate, token]);

  useEffect(() => {
    if (!id || !amount) {
      navigate("/transaction");
      analytics.track("Redirected to Transaction Due to Missing ID/Amount"); // Track missing ID or amount
    }

    if (transaction.isTransactionComplete) {
      navigate("/transaction/complete");
      analytics.track("Transaction Complete, Redirect to Complete Page"); // Track when transaction is complete
    }

    dispatch(
      fetchUsers({
        client_id: "APIGetBitRemitWeb",
        country_code: "+91",
        phone_number: id,
      })
    );
    dispatch(fetchExchangeRate());
    analytics.track("Exchange Rate Fetched"); // Track exchange rate fetched
  }, [dispatch, id, transaction.isTransactionComplete, amount, navigate]);

  const handleConfirm = () => {
    setOpen(true);
    analytics.track("Payment Confirmation Started", { amount, id }); // Track payment confirmation start
    dispatch(
      sendPayment({
        destinationCurrency: "INR",
        phoneNumber: id,
        sourceCurrency: "USD",
        amount: parseFloat(amount),
        purpose,
        is_instant,
      })
    );
  };

  const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    maxWidth: "90%",
    bgcolor: "white",
    borderRadius: 2,
    boxShadow: 24,
    p: 4,
  };

  return (
    <CroboContainer>
      {loading ? (
        <Box py={3}>
          <Loader />
        </Box>
      ) : (
        <Container maxWidth="md" sx={{ mt: 3, mb: 5 }}>
          {/* Transaction Summary Card */}
          <Card
            elevation={0}
            sx={{
              borderRadius: 2,
              border: "1px solid",
              borderColor: "grey.200",
              mb: 3,
              overflow: "hidden",
            }}
          >
            {/* Transfer Type Banner */}
            <Box
              sx={{
                bgcolor: "brand.main",
                py: 1,
                px: 2,
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <Box
                sx={{
                  bgcolor: "brandLight.main",
                  color: "brand.main",
                  px: 2,
                  py: 0.5,
                  borderRadius: 1,
                  fontWeight: 600,
                }}
              >
                <Typography variant="body1">
                  {is_instant ? "Instant Transfer" : "High Value Transfer"}
                </Typography>
              </Box>
            </Box>

            {/* Exchange Rate */}
            <Box
              sx={{
                textAlign: "center",
                p: 2,
                borderBottom: "1px solid",
                borderColor: "grey.100",
              }}
            >
              <Typography variant="body2" color="text.secondary" gutterBottom>
                Exchange Rate
              </Typography>
              <Typography variant="h5" fontWeight={600} color="brand.main">
                $1 = ₹{is_instant ? instantExchangeRate : exchangeRate}
              </Typography>
            </Box>

            {/* Amount Details */}
            <Box
              sx={{
                p: { xs: 2, sm: 3 },
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: { xs: 2, sm: 4 },
              }}
            >
              {/* Source Amount */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  p: { xs: 1.5, sm: 2 },
                  borderRadius: 1,
                }}
              >
                <Typography variant="h5" fontWeight={600} color={"brand.main"}>
                  ${amount}
                </Typography>
              </Box>

              {/* Arrow Icon */}
              <Box
                sx={{
                  color: "text.secondary",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <EastIcon sx={{ fontSize: 28 }} />
              </Box>

              {/* Destination Amount */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
              
                  p: { xs: 1.5, sm: 2 },
                  borderRadius: 1,
        
                }}
              >
                <Typography variant="h5" fontWeight={600} color="brand.main">
                  ₹
                  {is_instant
                    ? amountRoundOff(instantExchangeRate * amount)
                    : amountRoundOff(exchangeRate * amount)}
                </Typography>
              </Box>
            </Box>
          </Card>

          {/* Details Accordions */}
          <Box sx={{ mb: 3 }}>
            {user.loading || loading ? (
              <Loader />
            ) : (
              <>
                {bankDetails?.plaid_institution_name && (
                  <Accordion
                    elevation={0}
                    sx={{
                      mb: 2,
                      border: "1px solid",
                      borderColor: "grey.200",
                      "&:before": { display: "none" },
                      borderRadius: 2,
                      overflow: "hidden",
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        "&.Mui-expanded": {
                          borderBottom: "1px solid",
                          borderColor: "grey.200",
                        },
                      }}
                    >
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 1 }}
                      >
                        <AccountBalanceIcon color="action" />
                        <Typography variant="subtitle1" fontWeight={600}>
                          Your Debit Bank Details
                        </Typography>
                      </Box>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              p: 1.5,

                              borderRadius: 1,
                            }}
                          >
                            <Typography color="text.secondary">
                              Source bank
                            </Typography>
                            <Typography fontWeight={500}>
                              {bankDetails.plaid_institution_name}
                            </Typography>
                          </Box>
                        </Grid>
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              p: 1.5,

                              borderRadius: 1,
                            }}
                          >
                            <Typography color="text.secondary">
                              Account number
                            </Typography>
                            <Typography fontWeight={500}>
                              {bankDetails.plaid_account_mask}
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                )}

                <Accordion
                  elevation={0}
                  sx={{
                    mb: 2,
                    border: "1px solid",
                    borderColor: "grey.200",
                    "&:before": { display: "none" },
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      "&.Mui-expanded": {
                        borderBottom: "1px solid",
                        borderColor: "grey.200",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <PersonIcon color="action" />
                      <Typography variant="subtitle1" fontWeight={600}>
                        Recipient Details
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      {fetchUser?.name && (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              p: 1,

                              borderRadius: 1,
                            }}
                          >
                            <Typography color="text.secondary">Name</Typography>
                            <Typography fontWeight={500}>
                              {fetchUser.name}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                      {fetchUser?.email && (
                        <Grid item xs={12}>
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                              p: 1,

                              borderRadius: 1,
                            }}
                          >
                            <Typography color="text.secondary">
                              Email
                            </Typography>
                            <Typography fontWeight={500}>
                              {fetchUser.email}
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </AccordionDetails>
                </Accordion>

                <Accordion
                  elevation={0}
                  sx={{
                    mb: 2,
                    border: "1px solid",
                    borderColor: "grey.200",
                    "&:before": { display: "none" },
                    borderRadius: 2,
                    overflow: "hidden",
                  }}
                >
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    sx={{
                      "&.Mui-expanded": {
                        borderBottom: "1px solid",
                        borderColor: "grey.200",
                      },
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                      <AccountBalanceWalletIcon color="action" />
                      <Typography variant="subtitle1" fontWeight={600}>
                        Recipient Bank Details
                      </Typography>
                    </Box>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Grid container>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: 1,

                            borderRadius: 1,
                          }}
                        >
                          <Typography color="text.secondary">
                            Bank Name
                          </Typography>
                          <Typography fontWeight={500}>
                            {fetchUser?.bankDetails?.bankName}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: 1,

                            borderRadius: 1,
                          }}
                        >
                          <Typography color="text.secondary">
                            Account Number
                          </Typography>
                          <Typography fontWeight={500}>
                            {fetchUser?.bankDetails?.bankAccountNumber}
                          </Typography>
                        </Box>
                      </Grid>
                      <Grid item xs={12}>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "space-between",
                            p: 1,

                            borderRadius: 1,
                          }}
                        >
                          <Typography color="text.secondary">
                            IFSC Code
                          </Typography>
                          <Typography fontWeight={500}>
                            {fetchUser?.bankDetails?.ifsc}
                          </Typography>
                        </Box>
                      </Grid>
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              </>
            )}
          </Box>

          {/* Confirm Button */}
          <Button
            variant="contained"
            fullWidth
            onClick={handleConfirm}
            size="large"
            disabled={loading || user.loading}
            sx={{
              py: 2,
              bgcolor: "brand.main",
              "&:hover": {
                bgcolor: "brand.dark",
              },
              "&:disabled": {
                bgcolor: "grey.300",
              },
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                fontWeight: 600,
                color: "white",
              }}
            >
              Confirm Transfer
            </Typography>
          </Button>
          <BottomBranding />
        </Container>
      )}

      {/* Processing Modal */}
      <Modal
        open={open}
        aria-labelledby="processing-modal-title"
        aria-describedby="processing-modal-description"
        disableAutoFocus={true}
      >
        <Box sx={modalStyle}>
          <Box sx={{ textAlign: "center", mb: 3 }}>
            <Typography
              variant="h6"
              component="h2"
              gutterBottom
              fontWeight={600}
            >
              Processing Your Transfer
            </Typography>
            <Typography color="text.secondary">
              Please don't close the browser or tabs while we process your
              transaction
            </Typography>
          </Box>
          <Loader />
        </Box>
      </Modal>
    </CroboContainer>
  );
};

export default Confirm;
