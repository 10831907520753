import { Box, Button, Typography } from '@mui/material';
import React from 'react';

const GoogleButton = ({ onClick }) => {
  return (
    <Box>
      <Button
        onClick={onClick}
        fullWidth
        variant="outlined"
        size="large"
        startIcon={
          <img
            src="https://www.cdnlogo.com/logos/g/35/google-icon.svg"
            alt="google"
            style={{ height: "35px" }}
          />
        }
        sx={{
          backgroundColor: "white",
          color: "#000",
          '&:hover': {
            backgroundColor: "#f0f0f0", // light grey color
          },
        }}
      >
        <Typography marginX={2}>Sign in with Google</Typography>
      </Button>
    </Box>
  );
};

export default GoogleButton;