import { Box, Typography } from '@mui/material';
import React from 'react'

const BottomBranding = () => {
  return (
    <Box sx={{ m: 10 }}>
      <Typography variant="subtitle1" textAlign="center" color="textSecondary">
        CROBO v.1.0.1
      </Typography>
    </Box>
  );
}

export default BottomBranding