import React from "react";
import { CssBaseline, Container, Box, Typography } from "@mui/material";
import "../App.css";
import Footer from "../components/footer";
import HomeContent from "../components/homeContent";
import Nav from "../components/nav";

const Home = () => {
  return (
    <>
      <CssBaseline />

      <Box sx={{ height: "55px", bgcolor: "brandLight.main" }}>
        <Nav />
      </Box>
      <Box sx={{ bgcolor: "brandLight.main" }}>
        <Container>
          <HomeContent />
        </Container>
      </Box>
      <Footer />
      <Box bgcolor={"white"} p={2}>
        <Typography textAlign={"center"} variant="h6">
          Built by Ex-Amazonians
        </Typography>
      </Box>
    </>
  );
};

export default Home;
