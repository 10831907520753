import React, { useState, useEffect } from "react";
import {
  auth,
  RecaptchaVerifier,
  signInWithPhoneNumber,
  googleProvider,
  firebaseErrorHandler,
  PhoneAuthProvider,
  signInWithPopup,
} from "../service/firebase";
import {
  Container,
  Button,
  Box,
  Typography,
  OutlinedInput,
  InputAdornment,
  Grid,
  Divider,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../reducers/userReducer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import ErrorComponent from "../components/errorComponent";
import Loader from "../components/loader";
import { analytics } from "../helper/helper";
import CroboContainer from "../components/croboContainer";
import OTPInput from "react-otp-input";
import GoogleButton from "../components/googleButton";

const Login = () => {
  const [phone, setPhone] = useState("");
  const [otp, setOtp] = useState("");
  const [confirmationResult, setConfirmationResult] = useState(null);
  const [otpSent, setOtpSent] = useState(false);
  const [loader, setLoader] = useState(false);
  const [error, setError] = useState("");
  const [cooldown, setCooldown] = useState(false);
  const [cooldownTime, setCooldownTime] = useState(60);
  const [isResendDisabled, setIsResendDisabled] = useState(true);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { profile, pin, isNewUser } = useSelector((state) => state.userAuth);
  useEffect(() => {
    const handleProfileNavigation = () => {
      if (isNewUser) {
        navigate("/new");
      } else if (!pin) {
        navigate("/pin/set");
      } else {
        navigate("/pin");
      }
    };
    resetState();
    if (profile) {
      handleProfileNavigation();
    }
  }, [navigate, profile, pin, isNewUser]);

  useEffect(() => {
    initializeRecaptcha();
  }, []);

  useEffect(() => {
    const handleCooldownTimer = () => {
      let interval;
      if (cooldown) {
        interval = setInterval(() => {
          setCooldownTime((prevTime) => {
            if (prevTime <= 1) {
              clearInterval(interval);
              setCooldown(false);
              setIsResendDisabled(false);
              return 60;
            }
            return prevTime - 1;
          });
        }, 1000);
      }
      return () => clearInterval(interval);
    };
    handleCooldownTimer();
  }, [cooldown]);

  const resetState = () => {
    setPhone("");
    setOtp("");
    setOtpSent(false);
    setConfirmationResult(null);
    setError("");
  };

  const initializeRecaptcha = () => {
    if (!window.recaptchaVerifier) {
      auth.appVerificationDisabledForTesting = true;
      window.recaptchaVerifier = new RecaptchaVerifier(
        auth,
        "recaptcha-container",
        {
          size: "invisible",
          callback: () => {},
        }
      );
      window.recaptchaVerifier.render().catch((error) => {
        console.error("Recaptcha render failed:", error);
      });
    }
  };

  const handleSignInWithPhone = async () => {
    try {
      setLoader(true);
      analytics.track("OTP Requested", { phone: `+1${phone}` });
      if (cooldown) {
        setError("Please wait a moment before retrying.");
        setLoader(false);
        return;
      }
      const appVerifier = window.recaptchaVerifier;
      const confirmationResult = await signInWithPhoneNumber(
        auth,
        `+1${phone}`,
        appVerifier
      );
      setConfirmationResult(confirmationResult);
      setOtpSent(true);
      setLoader(false);
      setIsResendDisabled(true);
      setCooldown(true);
      analytics.track("OTP Sent", { phone });
    } catch (error) {
      handleError(error);
    }
  };

  const handleError = (error) => {
    const errorMessage = firebaseErrorHandler(error.code);
    setError(errorMessage);
    setLoader(false);
    analytics.track("OTP Request Failed", { phone, error: errorMessage });
    if (error.code === "auth/too-many-requests") {
      setCooldown(true);
      setTimeout(() => setCooldown(false), 60000);
    }
  };

  const handleVerifyOtp = async () => {
    setLoader(true);
    analytics.track("OTP Verification Attempt", { phone, otp });
    if (confirmationResult) {
      try {
        const result = await confirmationResult.confirm(otp);
        analytics.identify(`+1${phone}`, {});
        console.log(result);
        dispatch(
          login({
            phone: result.user.phoneNumber,
            authToken: result.user.accessToken,
          })
        );
        analytics.track("Login Success", { phone });
      } catch (error) {
        handleError(error);
      }
    }
  };

  const handleResendOtp = () => {
    analytics.track("OTP Resent", { phone });
    setIsResendDisabled(true);
    handleSignInWithPhone();
  };

  const handleOAuthSignIn = async (provider, method) => {
    try {
      setLoader(true);
      const result = await signInWithPopup(auth, provider);
      const user = auth.currentUser;
      const providerData = user.providerData;
      const isPhoneLinked = providerData.some(
        (provider) => provider.providerId === PhoneAuthProvider.PROVIDER_ID
      );
      if (isPhoneLinked) {
        dispatch(
          login({
            phone: result.user.phoneNumber,
            authToken: result.user.accessToken,
          })
        );
        localStorage.setItem("phone", result.user.phoneNumber);
        localStorage.setItem("photoURL", result.user.photoURL);
        analytics.identify(result.user.phoneNumber, {});
        result && analytics.track("Login Success", { method });
      } else {
        navigate("/link/phone");
      }
    } catch (error) {
      const errorMessage = firebaseErrorHandler(error.code);
      console.log(error);
      setError(errorMessage);
      setLoader(false);
      analytics.track(`${method} Sign-In Failed`, { error: errorMessage });
    }
  };

  const handleGoogleSignIn = () => handleOAuthSignIn(googleProvider, "Google");
  // const handleAppleSignIn = () => handleOAuthSignIn(appleProvider, "Apple");

  const renderPhoneNumberInput = () => (
    <Box sx={{ mb: 2 }}>
      <OutlinedInput
        placeholder="(123) 456-7890"
        value={phone}
        sx={{
          bgcolor: "white",
          borderRadius: 2,
          '& .MuiOutlinedInput-input': {
            py: 1.5,
            fontSize: '1.1rem',
          }
        }}
        type="tel"
        disabled={otpSent}
        onChange={(e) => {
          const p = e.target.value;
          setPhone(p.toString());
          if (p.toString().length > 10) {
            setError("Invalid Phone Number");
          } else {
            setError(null);
          }
        }}
        startAdornment={
          <InputAdornment position="start">
            <Box sx={{ 
              display: 'flex', 
              alignItems: 'center',
              borderRight: 1,
              borderColor: 'grey.300',
              pr: 1,
              mr: 1
            }}>
              <img
                src="https://upload.wikimedia.org/wikipedia/en/a/a4/Flag_of_the_United_States.svg"
                height={20}
                width={30}
                alt="+1"
                style={{ borderRadius: 2 }}
              />
              <Typography sx={{ ml: 1, color: 'text.secondary' }}>
                +1
              </Typography>
            </Box>
          </InputAdornment>
        }
        fullWidth
        inputProps={{
          maxLength: 10,
          inputMode: "numeric",
          pattern: "[0-9]*",
        }}
      />
    </Box>
  );

  const renderOtpInput = () => (
    <Box sx={{ py: 3 }}>
      <Typography variant="subtitle2" sx={{ mb: 2, color: "text.secondary" }}>
        Enter verification code
      </Typography>
      <OTPInput
        value={otp}
        onChange={setOtp}
        numInputs={6}
        shouldAutoFocus={true}
        inputType="number"
        renderSeparator={<span style={{ width: "4px" }} />}
        renderInput={(props) => (
          <input
            {...props}
            style={{
              width: "100%",
              height: "40px",
              borderRadius: "8px",
              border: "1px solid #E0E0E0",
              fontSize: 18,
              textAlign: "center",
              fontFamily: "Arial",
            }}
          />
        )}
        containerStyle={{
          justifyContent: "center",
          gap: "2px",
        }}
      />

      <Box sx={{ mt: 3 }}>
        {loader ? (
          <Loader />
        ) : (
          <Button
            variant="contained"
            color="brand"
            onClick={handleVerifyOtp}
            fullWidth
            sx={{
              py: 1.5,
              borderRadius: 2,
              textTransform: "none",
              fontSize: "1rem",
            }}
            disabled={otp.length !== 6}
          >
            Verify Code
          </Button>
        )}
      </Box>

      <Box sx={{ mt: 3, textAlign: "center" }}>
        <Typography color="text.secondary" variant="body2">
          {cooldown
            ? `Resend code in ${cooldownTime}s`
            : "Didn't receive the code?"}
        </Typography>
        <Button
          onClick={handleResendOtp}
          disabled={isResendDisabled}
          sx={{
            mt: 1,
            textTransform: "none",
            fontSize: "0.9rem",
          }}
        >
          Send again
        </Button>
      </Box>
    </Box>
  );

  const renderProceedButton = () => (
    <Box sx={{ mt: 2 }}>
      {loader ? (
        <Loader />
      ) : (
        <Button
          onClick={handleSignInWithPhone}
          id="sign-in-button"
          variant="contained"
          color="brand"
          fullWidth
          sx={{
            py: 1.5,
            borderRadius: 2,
            textTransform: 'none',
            fontSize: '1rem'
          }}
          disabled={phone.length !== 10 || cooldown}
        >
          Continue
        </Button>
      )}
    </Box>
  );

  const renderTermsAndConditions = () => (
    <Grid marginTop={2}>
      {location.pathname === "/signup" && (
        <Typography variant="body2" textAlign={"center"}>
          By continuing, you agree to our{" "}
          <Link to={"/tnc"}>Terms of Service</Link> and <br /> confirm that you
          have read our <Link to={"/privacy-policy"}>Privacy Policy</Link> to
          learn <br /> how we collect, use, and share your data.
        </Typography>
      )}
    </Grid>
  );

  const renderFooterLinks = () => (
    <Box>
      {location.pathname === "/login" ? (
        <Typography textAlign={"center"} marginTop={4}>
          Don't have an account?
          <Link to={"/signup"}>
            <Button variant="text">Sign Up</Button>
          </Link>
        </Typography>
      ) : (
        <Typography textAlign={"center"} marginTop={4}>
          Already have an account?
          <Link to={"/login"}>
            <Button variant="text">Login</Button>
          </Link>
        </Typography>
      )}
    </Box>
  );

  const renderSocialButtons = () => (
    <Box sx={{ mt: 3, mb: 2 }}>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center',
        my: 3
      }}>
        <Divider sx={{ flex: 1 }} />
        <Typography sx={{ px: 2, color: 'text.secondary' }} variant="body2">
          OR
        </Typography>
        <Divider sx={{ flex: 1 }} />
      </Box>
      <GoogleButton 
        onClick={handleGoogleSignIn}
        sx={{
          borderRadius: 2,
          py: 1.5,
          border: 1,
          borderColor: 'grey.300'
        }}
      />
    </Box>
  );

  return (
    <CroboContainer>
      <Container maxWidth="sm" sx={{ px: 2 }}>
        <Box sx={{ 
          pt: { xs: 6, sm: 10 }, 
          pb: 4,
        }}>
          <Box sx={{ mb: 4, textAlign: 'center' }}>
            {location.pathname === "/login" ? (
              <>
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                  Welcome back
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Login to send money to your family in India
                </Typography>
              </>
            ) : (
              <>
                <Typography variant="h5" sx={{ mb: 1, fontWeight: 600 }}>
                  Sign up 
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Sign up to send money to your family in India
                </Typography>
              </>
            )}
          </Box>

          {renderPhoneNumberInput()}
          {error && <ErrorComponent error={error} />}
          {!otpSent ? renderProceedButton() : renderOtpInput()}
          {!otpSent && !loader && renderSocialButtons()}

          <Box sx={{ mt: 'auto', pt: 4 }}>
            {renderTermsAndConditions()}
            {renderFooterLinks()}
          </Box>
        </Box>
      </Container>
    </CroboContainer>
  );
};

export default Login;
