import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import CroboContainer from '../components/croboContainer'
import { Container, Typography, Box, Button, Grid, Link, TextField } from '@mui/material';
import { LinkedIn } from '@mui/icons-material';
import usFlag from '../assets/us.png';
import indiaFlag from '../assets/in.png';
import AG from '../assets/ag.jpg';
import CM from '../assets/cm.jpg';
import { subscribeUser, clearMessage } from '../reducers/userReducer';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
    const navigate = useNavigate();
    const [email, setEmail] = useState('');
    const dispatch = useDispatch();
    const { customMessage, error } = useSelector((state) => {
        return state.userAuth;
    });

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(subscribeUser(email));
        setEmail('');
    };

    const handleOnChange = (e) =>{
        setEmail(e.target.value);
    };

    const handleStartTransfer = () => {
        navigate('/pin');
    };

    useEffect(() => {
        if (customMessage || error) {
            const timer = setTimeout(() => {
                dispatch(clearMessage());
            }, 30000);

            return () => clearTimeout(timer);
        }
    }, [customMessage, error, dispatch]);
    return (
      <CroboContainer>
        <Container maxWidth="lg">
          <Box
            my={2}
            mt="55px"
            textAlign="center"
            sx={{
              background: "white",
              borderRadius: 2,
              padding: 4,
            }}
          >
            <Typography
              variant="h4"
              component="h1"
              gutterBottom
              sx={{
                color: "#36454F",
                fontWeight: "800",
                marginBottom: 2,
              }}
            >
              About Us
            </Typography>

            <Typography
              sx={{ color: "#36454F", fontWeight: "bold" }}
              marginTop={4}
              variant="body1"
              textAlign={"start"}
              paragraph
            >
              Welcome to our platform, your go-to solution for the quickest and
              fee-free gifting and money transfer services from the USA to
              India. We're committed to providing you with a seamless and secure
              way to send gifts and money to your loved ones across borders,
              without any extra charges. Our customer-centric approach, combined
              with cutting-edge technology, ensures that your hard-earned money
              reaches its destination swiftly and without any hassle. Join us in
              making gifting & money transfers a breeze while saving you time
              and money. Your trust is our priority as we facilitate the fastest
              and fee-free money sending experience.
            </Typography>

            <Button
              variant="contained"
              onClick={handleStartTransfer}
              size="large"
              sx={{
                backgroundColor: "brand.main",
                color: "white",
                fontWeight: "bold",
                padding: "12px 40px",
                fontSize: "1.1rem",
                borderRadius: 2,
                transition: "all 0.3s ease",
                boxShadow: "0 4px 15px rgba(0,0,0,0.1)",
                marginTop: 6,
              }}
            >
              Start Transfer
            </Button>

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={6}
              sx={{
                "& img": {
                  transition: "transform 0.3s ease",
                  "&:hover": {
                    transform: "scale(1.1)",
                  },
                },
              }}
            >
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "#36454F", fontWeight: "bold" }}
              >
                - - - -
              </Typography>
              <img
                src={usFlag}
                alt="USA Flag"
                style={{ marginRight: 12 }}
                width={40}
                height={25}
              />
              <img
                src={indiaFlag}
                alt="India Flag"
                style={{ marginLeft: 12 }}
                width={40}
                height={25}
              />
              <Typography
                variant="body1"
                component="span"
                sx={{ color: "#36454F", fontWeight: "bold" }}
              >
                - - - -
              </Typography>
            </Box>
            <Typography
              sx={{ color: "#36454F" }}
              variant="body1"
              component="span"
            >
              <span style={{ fontWeight: "bold" }}>US</span> to{" "}
              <span style={{ fontWeight: "bold" }}>INDIA</span> in few steps
            </Typography>
          </Box>

          <Box my={8}>
            <Typography
              variant="h3"
              component="h2"
              gutterBottom
              textAlign="center"
              sx={{
                color: "#36454F",
                fontWeight: "800",
                marginBottom: 6,
              }}
            >
              Meet our Team
            </Typography>
            <Grid container spacing={6} justifyContent="center">
              <Grid item xs={12} sm={6} md={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    padding: 4,
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0 6px 25px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <img
                    alt={"Abhay Agarwal"}
                    src={AG}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: "50%",
                      margin: "0 auto",
                    }}
                  />
                  <Typography variant="h6" component="h3" gutterBottom>
                    {"Abhay Agarwal"}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {"CEO"}
                  </Typography>
                  <Link
                    href="https://www.linkedin.com/in/aabhay/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIn sx={{ fontSize: 50 }} color="primary" />
                  </Link>
                </Box>
              </Grid>
              <Grid item xs={12} sm={12} md={6}>
                <Box
                  sx={{
                    textAlign: "center",
                    padding: 4,
                    borderRadius: "16px",
                    backgroundColor: "#ffffff",
                    boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
                    transition: "transform 0.3s ease",
                    "&:hover": {
                      transform: "translateY(-10px)",
                      boxShadow: "0 6px 25px rgba(0,0,0,0.1)",
                    },
                  }}
                >
                  <img
                    alt={"Chandni Malik"}
                    src={CM}
                    style={{
                      width: 100,
                      height: 100,
                      borderRadius: "50%",
                      margin: "0 auto",
                    }}
                  />
                  <Typography variant="h6" component="h3" gutterBottom>
                    {"Chandni Malik"}
                  </Typography>
                  <Typography variant="subtitle1" gutterBottom>
                    {"CCO"}
                  </Typography>
                  <Link
                    href="https://www.linkedin.com/in/chandni-malik-2309b14/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <LinkedIn sx={{ fontSize: 50 }} color="primary" />
                  </Link>
                </Box>
              </Grid>
            </Grid>
          </Box>

          <Box
            my={2}
            sx={{
              background: "linear-gradient(135deg, #f8f9fa 0%, #e9ecef 100%)",
              borderRadius: "16px",
              padding: 2,
              boxShadow: "0 4px 20px rgba(0,0,0,0.05)",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "#36454F",
                fontWeight: "800",
                marginBottom: 2,
                textAlign: "center",
              }}
            >
              Subscribe to our newsletter
            </Typography>
            <Box
              display="flex"
              flexDirection="column"
              alignItems="center"
              width="100%"
              maxWidth="600px"
              mx="auto"
              gap={2}
            >
              <TextField
                variant="outlined"
                type="email"
                placeholder="Enter your email"
                sx={{
                  bgcolor: "#ffffff",
                  borderRadius: 2,
                  "& .MuiOutlinedInput-root": {
                    "&:hover fieldset": {
                      borderColor: "brand",
                    },
                  },
                }}
                fullWidth
                onChange={handleOnChange}
                value={email}
              />
              <Button
                onClick={handleSubmit}
                variant="contained"
                size="large"
                sx={{
                  backgroundColor: "brand.main",
                  whiteSpace: "nowrap",
                  padding: "14px 30px",
                  fontWeight: "bold",
                  "&:hover": {
                    backgroundColor: "#128d6f",
                    transform: "translateY(-2px)",
                  },
                  transition: "all 0.3s ease",
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Box>

          <Box sx={{ my: 6 }}>
            <Typography
              variant="subtitle1"
              textAlign="center"
              color="textSecondary"
              sx={{ opacity: 0.7 }}
            >
              CROBO v.1.0.1
            </Typography>
          </Box>
        </Container>
      </CroboContainer>
    );
}

export default AboutUs
