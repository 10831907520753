// Auth.js
import React, { useEffect } from "react";
import "./App.css";
import router from "./route";
import { createTheme, ThemeProvider } from "@mui/material";
import { RouterProvider } from "react-router-dom";
import { useDispatch } from "react-redux";
import { bannerData, fetchEnv } from "./reducers/resourceReducer";
import RouteChangeTracker from "./components/RouteChangeTracker";
import { Camera } from "@capacitor/camera";
import { analytics, errorAnalytics } from "./helper/helper";
import { ScreenOrientation } from "@capacitor/screen-orientation";
import { VersionInfo } from "./version";

const theme = createTheme({
  palette: {
    brand: {
      main: "#15ad87",
      dark: "#129b79",
    },
    brandDark: {
      main: "#36454F",
      text: "white",
    },
    brandLight: {
      main: "#EBF4F6",
      text: "black",
    },
  },
});

const Auth = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    // Fetch environment and banner data
    dispatch(fetchEnv());
    dispatch(bannerData());

    // Request camera permissions on app start
    const requestCameraPermissions = async () => {
      try {
        const permissionStatus = await Camera.checkPermissions();

        if (permissionStatus.camera !== "granted") {
          const request = await Camera.requestPermissions();
          console.log("Camera permission status:", request.camera);
        }
      } catch (error) {
        errorAnalytics("Error requesting camera permissions", { error });
      }
    };

    requestCameraPermissions();

    // Lock screen orientation to portrait
    const lockOrientation = async () => {
      try {
        await ScreenOrientation.lock({
          orientation: "portrait",
        });
      } catch (error) {
        errorAnalytics("Error locking screen orientation:", error);
      }
    };

    lockOrientation();

    // Cleanup function to unlock orientation when component unmounts
    return () => {
      const unlockOrientation = async () => {
        try {
          await ScreenOrientation.unlock();
        } catch (error) {
          console.error("Error unlocking screen orientation:", error);
        }
      };
      unlockOrientation();
    };
  }, [dispatch]);

  return (
    <>
    <ThemeProvider theme={theme}>
      <RouterProvider router={router}>
        <RouteChangeTracker />
      </RouterProvider>
    </ThemeProvider>
    <VersionInfo/>
    </>
  );
};

export default Auth;
