import {
    Typography,
    Stepper,
    Step,
    StepLabel,
    StepContent,
    styled,
    StepConnector,
} from "@mui/material";

const steps = [
    {
        label: "Sign Up And Enter Your Details",
        description:
            "Begin your money transfer journey by signing up for our service.",
    },
    {
        label: "Complete Your KYC",
        description:
            "Fulfill your Know Your Customer (KYC) requirements securely and efficiently.",
    },
    {
        label: "Enter Recipient Bank Details",
        description:
            "Input the necessary bank details of your recipient for a smooth transfer process.",
    },
    {
        label: "Confirm Payment",
        description:
            "Once you're done, confirm the payment, and your funds will be on their way.",
    },
];

const CustomStepConnector = styled(StepConnector)(({ theme }) => ({
    "& .MuiStepConnector-line": {
        borderColor: "#15ad87",
        borderWidth: 3,
        marginLeft: "6px",
    },
}));

const CustomStep = styled(Step)(({ theme }) => ({
    "& .MuiStepLabel-root .Mui-completed": {
        padding: "0px",
        color: "#15ad87",
    },
    "& .MuiStepLabel-root .Mui-active": {
        color: "#15ad87",
    },
    "& .MuiCollapse-root-MuiStepContent-transition .Mui-active": {
        color: "#15ad87",
    },
    "& .MuiStepContent-root": {
        color: "#15ad87",
        borderColor: "#15ad87",
        marginLeft: "18px",
        paddingLeft: "27px",
        borderWidth: 3,
    },
    "& .MuiStepLabel-root .MuiStepIcon-root": {
        fontSize: "40px",
    },
    "& .MuiStepLabel-root": {
        padding: "0px",
    },
}));

const HomeStepper = () => (
    <Stepper
        orientation="vertical"
        sx={{ py: 4 }}
        connector={<CustomStepConnector />}
    >
        {steps.map((step, index) => (
            <CustomStep key={index} active>
                <StepLabel>
                    <Typography variant="h6">{step.label}</Typography>
                </StepLabel>
                <StepContent>
                    <Typography variant="subtitle1" color="black">
                        {step.description}
                    </Typography>
                </StepContent>
            </CustomStep>
        ))}
    </Stepper>
);

export default HomeStepper;
