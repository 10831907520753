import React, { useEffect, useState } from "react";
import CroboContainer from "../components/croboContainer";
import {
  Box,
  Typography,
  Button,
  List,
  ListItemIcon,
  Modal,
  Container,
  TextField,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  CircularProgress,
} from "@mui/material";
import {
  AccountBalance as AccountBalanceIcon,
  HeadsetMic as HeadsetMicIcon,
  Note as NoteIcon,
  Policy as PolicyIcon,
  Gavel as GavelIcon,
  ExitToApp as ExitToAppIcon,
} from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import {
  apiRoutes,
  getProfile,
  remitStatusUpdate,
  setLoader,
  logout,
} from "../reducers/userReducer";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { runPersonaClient } from "../components/persona";
import BottomBranding from "../components/bottomBranding";
import Loader from '../components/loader';

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  maxWidth: "500px",
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const Profile = () => {
  return (
    <CroboContainer>
      <Container>
        <ProfileInternal />
      </Container>
    </CroboContainer>
  );
};

export const ProfileInternal = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { token, profile } = useSelector((state) => state.userAuth);
  const phoneStore = localStorage.getItem("phone") || null;

  const [open, setOpen] = useState(false);
  const [amount, setAmount] = useState(1);
  const [purpose, setPurpose] = useState();
  const [SSNStatus, setSSNStatus] = useState(0);
  const [userName, setUserName] = useState(null);
  const [transactionLimit, setTransactionLimit] = useState(null);
  const hideSSN = false;

  useEffect(() => {
    const fetchUser = async () => {
      const phone = localStorage.getItem("phone");
      const token = localStorage.getItem("token");
      const config = {
        method: "post",
        url: apiRoutes.fetchUsers,
        data: {
          client_id: "APIGetBitRemitWeb",
          country_code: "+1",
          phone_number: phone.substring(2),
          user_type: "sender",
        },
        headers: { "x-auth-token": token },
      };
      const payload = await axios.request(config);
      localStorage.setItem("fetchUser", JSON.stringify(payload.data));
      const userInfo = payload.data;
      if (userInfo && userInfo.transaction_limits) {
        const usdLimit = userInfo.transaction_limits.Transaction_limit.find(
          (limit) => limit.currency === "USD"
        );
        setTransactionLimit(usdLimit);
      }
    };

    fetchUser();
    const profileData = profile || JSON.parse(localStorage.getItem("profile"));
    if (profileData) {
      setUserName(profileData?.user_name);
      setSSNStatus(profileData.ssn_status);
    }
  }, [profile]);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const handleReferAndEarnClick = () => navigate("/referrals");
  const handleManageBankAccount = () => navigate("/manage-bank-account");
  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const onPersonaComplete = async () => {
    dispatch(setLoader(true));
    await new Promise((resolve) => setTimeout(resolve, 2000)); // 2 seconds delay
    dispatch(remitStatusUpdate({ token, isRefresh : 'false' }));
    dispatch(getProfile());
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    dispatch(setLoader(true));

    const token = localStorage.getItem("token");

    try {
      const config = {
        method: "post",
        url: apiRoutes.remitIdentityVerification,
        headers: { "x-auth-token": token },
        data: { type: "ssn" },
      };

      const payload = await axios.request(config);
      handleClose();
      await runPersonaClient(payload.data.inquiry_id, onPersonaComplete);
      dispatch(setLoader(false));
    } catch (error) {
      console.error("Error submitting data:", error);
    }
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      alignItems={"center"}
      width={"100%"}
    >
      <Box
        sx={{
          borderRadius: 2,
          pt: 4,
          alignContent: "center",
          width: "100%",
        }}
      >
        <UserProfile userName={userName} phoneStore={phoneStore} />
        <TransactionLimits transactionLimit={transactionLimit} />

        {!(SSNStatus !== 0 || hideSSN) && (
          <Button
            onClick={handleOpen}
            variant="contained"
            color="brand"
            size="large"
            fullWidth
          >
            Click here to get enhanced limits
          </Button>
        )}
        <SSNVerificationModal
          open={open}
          handleClose={handleClose}
          amount={amount}
          setAmount={setAmount}
          purpose={purpose}
          setPurpose={setPurpose}
          handleSubmit={handleSubmit}
        />
        <ProfileActions
          handleManageBankAccount={handleManageBankAccount}
          handleReferAndEarnClick={handleReferAndEarnClick}
          handleLogout={handleLogout}
        />
      <BottomBranding />
      </Box>
    </Box>
  );
};

const UserProfile = ({ userName, phoneStore }) => (
  <Box display="flex" justifyContent="start">
    <Box>
      {/* <Avatar
        sx={{
          backgroundColor: "brand.main",
          width: 50,
          height: 50,
          mb: 3,
        }}
      >
        <Typography variant="h6" color={"white"}>
          {userName?.letters}
        </Typography>
      </Avatar> */}
    </Box>
    <Box>
      <Typography variant="h4">Hi, {userName}</Typography>
      <Typography variant="body1" color="textSecondary">
        <Typography component={"span"} color="textSecondary" variant="body1">
          Phone:
        </Typography>
        {phoneStore}
      </Typography>
    </Box>
  </Box>
);

const TransactionLimits = ({ transactionLimit }) => (
  <Box my={2}>
    <Typography variant="h6">Transaction Limits</Typography>
    {!transactionLimit ? <Loader/> : (
      <Box
        sx={{
          bgcolor: "white",
          border: "1px solid",
          borderColor: "grey.300",
          borderRadius: 2,
          p: 2,
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Box>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              size={100}
              sx={{ color: "grey.200" }}
              variant="determinate"
              value={100}
            />
            <CircularProgress
              size={100}
              sx={{
                color: "brand.main",
                position: 'absolute',
                left: 0,
              }}
              variant="determinate"
              value={
                (transactionLimit.Daily / transactionLimit.Max_daily) * 100
              }
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body1">
                Daily <br /> Limit
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2">
            {transactionLimit.Daily} of {transactionLimit.Max_daily} left
          </Typography>
        </Box>
        <Box>
          <Box sx={{ position: "relative", display: "inline-flex" }}>
            <CircularProgress
              size={100}
              sx={{ color: "grey.200" }}
              variant="determinate"
              value={100}
            />
            <CircularProgress
              size={100}
              sx={{
                color: "brand.main",
                position: 'absolute',
                left: 0,
              }}
              variant="determinate"
              value={
                (transactionLimit.Weekly / transactionLimit.Max_weekly) * 100
              }
            />
            <Box
              sx={{
                top: 0,
                left: 0,
                bottom: 0,
                right: 0,
                position: "absolute",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography variant="body2" textAlign={"center"}>
                Weekly <br /> Limit
              </Typography>
            </Box>
          </Box>
          <Typography variant="body2">
            {transactionLimit.Weekly} of {transactionLimit.Max_weekly} left
          </Typography>
        </Box>
      </Box>
    )}
  </Box>
);

const SSNVerificationModal = ({
  open,
  handleClose,
  amount,
  setAmount,
  purpose,
  setPurpose,
  handleSubmit,
}) => (
  <Modal
    open={open}
    onClose={handleClose}
    aria-labelledby="modal-modal-title"
    aria-describedby="modal-modal-description"
  >
    <Container>
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Start your SSN Verification
        </Typography>
        <Box component="form">
          <TextField
            fullWidth
            label="Amount"
            value={amount}
            onChange={(e) => setAmount(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <TextField
            fullWidth
            label="Purpose of Transfer"
            value={purpose}
            onChange={(e) => setPurpose(e.target.value)}
            variant="outlined"
            margin="normal"
          />
          <FormControl component="fieldset" margin="normal">
            <FormLabel component="legend">Transfer Type</FormLabel>
            <RadioGroup
              row
              aria-label="transfer-type"
              name="transfer-type"
              defaultValue="self"
            >
              <FormControlLabel value="self" control={<Radio />} label="Self" />
              <FormControlLabel
                value="someone-else"
                control={<Radio />}
                label="Someone Else"
              />
            </RadioGroup>
          </FormControl>
          <Button
            variant="contained"
            color="brand"
            size="large"
            fullWidth
            onClick={handleSubmit}
          >
            Proceed
          </Button>
        </Box>
      </Box>
    </Container>
  </Modal>
);

const ProfileActions = ({
  handleManageBankAccount,
  handleReferAndEarnClick,
  handleLogout,
}) => (
  <List sx={{ display: "flex", justifyContent: "center" }}>
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        width: "100%",
        flexDirection: "column",
      }}
    >
      <ProfileActionButton
        onClick={handleManageBankAccount}
        icon={<AccountBalanceIcon sx={{ color: "black" }} />}
        text="Manage Bank Account"
      />
      <ProfileActionButton
        href="mailto:care@crobo.money"
        icon={<HeadsetMicIcon sx={{ color: "black" }} />}
        text="Contact Us"
      />
      <ProfileActionButton
        onClick={handleReferAndEarnClick}
        icon={<NoteIcon sx={{ color: "black" }} />}
        text="Refer and Earn"
      />
      <ProfileActionButton
        href="https://getbit-termsandconditions.s3.amazonaws.com/Privacy+Policy_GetBit_US_V2_08.23.2023.pdf"
        target="_blank"
        rel="noopener noreferrer"
        icon={<PolicyIcon sx={{ color: "black" }} />}
        text="Privacy Policy"
      />
      <ProfileActionButton
        href="https://getbit-termsandconditions.s3.amazonaws.com/Terms+of+Use_GetBit_US_V1_08.23.2023.pdf"
        target="_blank"
        rel="noopener noreferrer"
        icon={<GavelIcon sx={{ color: "black" }} />}
        text="Terms and Conditions"
      />
      <ProfileActionButton
        onClick={handleLogout}
        icon={<ExitToAppIcon sx={{ color: "black" }} />}
        text="Logout"
      />
    </Box>
  </List>
);

const ProfileActionButton = ({ onClick, href, icon, text, target, rel }) => (
  <Box>
    <Button
      variant="outlined"
      fullWidth
      onClick={onClick}
      href={href}
      target={target}
      rel={rel}
      sx={{
        width: "100%",
        height: 50,
        borderRadius: 2,
        mt: 1,
        backgroundColor: "white",
        color: "black",
        borderColor: "grey.300",
        display: "flex",
        justifyContent: "flex-start",
        "&:hover": {
          backgroundColor: "#d9dcdd",
          borderColor: "#d9dcdd",
        },
      }}
    >
      <ListItemIcon>{icon}</ListItemIcon>
      <Typography
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          fontWeight: "bold",
        }}
      >
        {text}
      </Typography>
    </Button>
  </Box>
);

export default Profile;
